var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"measurementListContainer",staticClass:"dij-diagrambuilder-inspections",on:{"scroll":_vm.handleScroll}},[(_vm.inspections[_vm.selectedInspectionIndex])?[_c('inspection-keypad',{ref:"keypad",on:{"next":_vm.nextMeasurement,"done":_vm.saveInspectionByInspectionNumber,"deleteInspection":_vm.deleteInspection,"forceSaveInspection":_vm.forceSaveInspection},model:{value:(_vm.inspections[_vm.selectedInspectionIndex]),callback:function ($$v) {_vm.$set(_vm.inspections, _vm.selectedInspectionIndex, $$v)},expression:"inspections[selectedInspectionIndex]"}})]:_vm._l((_vm.inspections),function(inspection,index){return _c('div',{key:inspection._id},[(
          inspection.type !== 'text' &&
          inspection.type !== 'arrow' &&
          inspection.type !== 'line'
        )?[_c('div',{staticClass:"dij-diagrambuilder-inspection",on:{"click":function($event){return _vm.selectInspection(inspection)}}},[_c('div',{staticClass:"dij-diagrambuilder-inspection-number",on:{"click":function($event){return _vm.onInspectionNumberClick(inspection)}}},[_c('p',[_vm._v(_vm._s(inspection.number))])]),_c('div',{staticClass:"dij-diagrambuilder-inspection-data"},[_c('p',{staticClass:"dij-diagrambuilder-inspection-type"},[_vm._v(" "+_vm._s(_vm.$t(inspection.type))+" "),(_vm.shouldShowVisualInspectionFine(inspection))?[_c('span',{staticStyle:{"color":"green"}},[_vm._v("- Fine")])]:_vm._e()],2),(_vm.tool === 'inspector')?[(inspection.type === 'visual')?[_c('div',[_vm._v(" "+_vm._s(_vm.$t('rubbed'))+": "+_vm._s(inspection.measurements.length !== 0 && inspection.measurements[0].value && inspection.measurements[0].value !== 'notCompleted' ? _vm.$t(inspection.measurements[0].value) : '--')+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('damaged'))+": "+_vm._s(inspection.measurements.length !== 0 && inspection.measurements[1].value && inspection.measurements[1].value !== 'notCompleted' ? _vm.$t(inspection.measurements[1].value) : '--')+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('pitted'))+": "+_vm._s(inspection.measurements.length !== 0 && inspection.measurements[2].value && inspection.measurements[2].value !== 'notCompleted' ? _vm.$t(inspection.measurements[2].value) : '--')+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('eroded'))+": "+_vm._s(inspection.measurements.length !== 0 && inspection.measurements[3].value && inspection.measurements[3].value !== 'notCompleted' ? _vm.$t(inspection.measurements[3].value) : '--')+" ")])]:[(
                    inspection.type !== 'visual' &&
                    inspection.type === 'runout' &&
                    !inspection.multipleMeasurements
                  )?[_c('div',{staticClass:"runout-one-measurement"},[_c('div',{style:(_vm.isMeasurementOk(inspection, 0)
                          ? 'color:green'
                          : inspection.measurements &&
                            inspection.measurements[0] &&
                            inspection.measurements[0].value === ''
                          ? 'color: black'
                          : 'color:red')},[_vm._v(" "+_vm._s(_vm.$t('tir'))+": "+_vm._s(inspection.measurements.length !== 0 && inspection.measurements[0] && inspection.measurements[0].value ? inspection.measurements[0].value : '--')+" ")]),_c('div',{style:(_vm.isMeasurementOk(inspection, 0)
                          ? 'color:green'
                          : inspection.measurements &&
                            inspection.measurements[0] &&
                            inspection.measurements[0].value === ''
                          ? 'color: black'
                          : 'color:red')},[_vm._v(" Pos : "+_vm._s(inspection.measurements.length !== 0 && inspection.measurements[1] && inspection.measurements[1].value !== '' ? inspection.measurements[1].value : '--')+" deg ")])])]:_vm._l((inspection.measurementCount),function(number){return _c('div',{key:number},[(inspection.type !== 'freefinding')?[(inspection.type === 'generic')?[_c('span',{staticClass:"generic-label",attrs:{"title":inspection.measurementName}},[_vm._v(" "+_vm._s(inspection.measurementName)+" ")]),_c('span',{staticClass:"generic-label",attrs:{"title":inspection.measurements[0].value}},[_vm._v(" "+_vm._s(inspection.measurements[0].value !== '' ? inspection.measurements[0].value : '--'))])]:[_c('div',{style:(_vm.isMeasurementOk(inspection, number - 1)
                              ? 'color:green'
                              : _vm.isInspectionMeasurementValid(
                                  inspection,
                                  number - 1
                                )
                              ? 'color: black'
                              : 'color:red')},[_vm._v(" Pos"+_vm._s(number)+" : "+_vm._s(inspection.measurements && inspection.measurements[number - 1] && inspection.measurements[number - 1].value ? inspection.measurements[number - 1].value : '--')+" ")])]]:_vm._e()],2)})]]:[(_vm.showExpectedValue(inspection))?[_c('b-field',{attrs:{"label":_vm.$t('expected_value')}},[_c('b-input',{attrs:{"type":"number","step":"any","disabled":_vm.isTaskCompleted},on:{"input":function($event){return _vm.updateToleranceDecimalPlaces(inspection)},"blur":function($event){return _vm.onBlur(inspection)}},model:{value:(inspection.expectedValue),callback:function ($$v) {_vm.$set(inspection, "expectedValue", $$v)},expression:"inspection.expectedValue"}})],1),_c('div',{staticClass:"dij-diagrambuilder-inspection-tolerances"},[_c('b-field',{attrs:{"label":inspection.toleranceType == 'normal' ||
                      inspection.toleranceType == 'positive'
                        ? _vm.$t('positive_tolerance')
                        : _vm.$t('negative_tolerance')}},[_c('b-input',{attrs:{"type":"number","step":"any","disabled":_vm.isTaskCompleted},on:{"blur":function($event){return _vm.onBlur(inspection)}},model:{value:(inspection.positiveTolerance),callback:function ($$v) {_vm.$set(inspection, "positiveTolerance", $$v)},expression:"inspection.positiveTolerance"}})],1),_c('b-field',{attrs:{"label":inspection.toleranceType == 'normal' ||
                      inspection.toleranceType == 'negative'
                        ? _vm.$t('negative_tolerance')
                        : _vm.$t('positive_tolerance')}},[_c('b-input',{attrs:{"type":"number","step":"any","disabled":_vm.isTaskCompleted},on:{"blur":function($event){return _vm.onBlur(inspection)}},model:{value:(inspection.negativeTolerance),callback:function ($$v) {_vm.$set(inspection, "negativeTolerance", $$v)},expression:"inspection.negativeTolerance"}})],1)],1)]:(_vm.showTolerance(inspection))?[_c('b-field',{staticClass:"dij-diagrambuilder-inspection-tolerance",attrs:{"label":_vm.$t('tolerance')}},[_c('b-input',{attrs:{"type":"number","step":"any","disabled":_vm.isTaskCompleted},on:{"blur":function($event){return _vm.onBlur(inspection)}},model:{value:(inspection.tolerance),callback:function ($$v) {_vm.$set(inspection, "tolerance", $$v)},expression:"inspection.tolerance"}})],1)]:[(inspection.type === 'generic')?[_c('b-field',{staticClass:"dij-diagrambuilder-inspection-tolerance",attrs:{"label":_vm.$t('generic_name')}},[_c('b-input',{attrs:{"type":"text","disabled":_vm.isTaskCompleted},on:{"blur":function($event){return _vm.onBlur(inspection)}},model:{value:(inspection.measurementName),callback:function ($$v) {_vm.$set(inspection, "measurementName", $$v)},expression:"inspection.measurementName"}})],1)]:_vm._e()]]],2)])]:_vm._e()],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }