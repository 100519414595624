import client from '../../../config/api';
import {
  LOCAL_SET_BUBBLES,
  LOCAL_REMOVE_BUBBLE,
  LOCAL_UPDATE_BUBBLE,
} from '../mutations/mutationTypes';

import { GET_TASK } from '../../tasks/actions/actionTypes';

export default {
  async SAVE_BUBBLES(_1, payload) {
    const { taskId, data } = payload;
    return client.post(`/bubbles/${taskId}`, data);
  },
  async GET_BUBBLES({ commit }, taskId) {
    const result = await client.get(`/bubbles/${taskId}`);
    commit(LOCAL_SET_BUBBLES, result.data);
  },
  async DELETE_BUBBLE({ commit }, bubbleId) {
    if (!bubbleId) {
      return;
    }

    await client.delete(`/bubble/${bubbleId}`);
    commit(LOCAL_REMOVE_BUBBLE, bubbleId);
  },
  async CREATE_BUBBLE(_1, data) {
    const { taskId, bubble } = data;
    if (!taskId || !bubble) {
      return;
    }

    const response = await client.post(`/bubble/${taskId}`, bubble);
    return response.data;
  },
  async UPDATE_BUBBLE({ commit, dispatch }, data) {
    const { _id, taskId } = data;
    if (!_id) {
      return;
    }
    await client.put(`/bubble/${_id}`, data);
    commit(LOCAL_UPDATE_BUBBLE, data);

    if (taskId) {
      await dispatch(GET_TASK, taskId, { root: true });
    }
  },
};
