<template>
  <div class="dij-diagrambuilder">
    <input
      ref="diagramChooser"
      type="file"
      class="hidden"
      accept="image/*;capture=camera"
      @change="importDiagram"
    />
    <div class="dij-diagrambuilder-canvas-container">
      <Toolbar
        :showToolbar="showToolbar"
        :mode="mode"
        :isImageSelected="isImageSelected"
        @enableMode="enableMode"
      />

      <div class="dij-diagrambuilder-zoomoption">
        <b-icon
          :class="isImageSelected ? '' : 'disabled'"
          icon="home"
          @click.native="zoomDefault"
        />
        <b-icon
          :class="isImageSelected ? '' : 'disabled'"
          icon="magnify-plus-outline"
          @click.native="zoomIn"
        />
        <b-icon
          :class="isImageSelected ? '' : 'disabled'"
          icon="magnify-minus-outline"
          @click.native="zoomOut"
        />
        <template>
          <div
            title="RAT"
            class="dij-diagrambuilder-toolbar-rat"
            :class="{
              disabled: !isImageSelected,
              'dij-tool-active': mode === 'rat',
            }"
            @click="enableRATMode"
          >
            <b-icon
              icon="hammer-wrench"
              size="is-medium"
              custom-class="no-hover"
            />
          </div>
        </template>

        <template v-if="tool === 'inspector'">
          <div
            title="Freefinding"
            class="dij-diagrambuilder-toolbar-freefinding"
            :class="{
              disabled: !isImageSelected,
              'dij-tool-active': mode === 'freefinding',
            }"
            @click="enableFreefindingMode"
          >
            <b-icon
              :icon="
                freeFindingTool
                  ? 'clipboard-text-search-outline'
                  : 'clipboard-text-search'
              "
              size="is-medium"
            ></b-icon>
          </div>
        </template>
      </div>

      <div
        class="dij-diagrambuilder-zoomoption"
        :class="showList ? 'bubble-zoom' : 'bubble-zoom-at-margin'"
      >
        <b-icon
          :class="isImageSelected ? '' : 'disabled'"
          icon="home"
          @click.native="zoomDefaultBubble"
        />
        <b-icon
          :class="isImageSelected ? '' : 'disabled'"
          icon="magnify-plus-outline"
          @click.native="zoomInBubble"
        />
        <b-icon
          :class="isImageSelected ? '' : 'disabled'"
          icon="magnify-minus-outline"
          @click.native="zoomOutBubble"
        />
      </div>

      <div
        class="dij-diagrambuilder-canvas"
        :id="'dij-diagrambuilder-canvas-' + task._id"
      >
        <canvas ref="canvas" :id="'canvas-diagram-' + task._id" />
        <div v-if="isImageSelected" class="dij-diagrambuild-job-info">
          <div>
            Document Number: {{ task.documentNumber }} Revision:
            {{ task.revision }}
          </div>
        </div>
        <div v-else-if="!showToolbar" class="dij-diagrambuilder-upload">
          Empty Diagram
        </div>
        <div v-else class="dij-diagrambuilder-upload">
          <b-button icon-left="paperclip" @click="chooseDiagramToImport"
            >Import a diagram</b-button
          >
        </div>
      </div>

      <inspection-list
        v-if="showList"
        ref="list"
        v-model="inspectionListItems"
        :tool="tool"
      />

      <div
        class="dij-diagrambuilder-inspection-detail"
        v-if="isItemDetailVisible && selectedItem.type !== 'rat'"
      >
        <header class="modal-card-head">
          {{ $t(selectedItem.type) }}
        </header>
        <section class="modal-card-body">
          <inspection-detail v-model="selectedItem" />
        </section>
        <footer class="modal-card-foot">
          <b-button
            v-if="isMoveMeasurementVisible"
            @click="enableMoveMeasurementMode"
            :type="explicitMoveMeasurementMode ? 'is-primary' : 'is-normal'"
            ><b-icon icon="arrow-all"
          /></b-button>
          <b-button
            type="is-danger"
            @click="deleteInspection"
            :loading="isLoading"
          >
            {{ $t('delete') }}
          </b-button>
          <b-button type="is-primary" @click="closeInspection">
            {{ $t('close') }}
          </b-button>
        </footer>
      </div>

      <div class="dij-diagrambuilder-inspection-rat" v-if="isItemRATVisible">
        <header class="modal-card-head">
          {{ $t(selectedItem.type.toUpperCase()) }}
        </header>
        <section class="modal-card-body">
          <RatInspectionDetail
            v-model="selectedItem"
            :tool="tool"
            ref="refRatDetail"
          />
        </section>
        <footer class="modal-card-foot">
          <b-button
            type="is-danger"
            @click="deleteInspection"
            :loading="isLoadingRatInspection"
          >
            {{ $t('delete') }}
          </b-button>
          <div>
            <b-button
              type="is-primary"
              @click="saveInspectionRat"
              :loading="isLoadingRatInspection"
            >
              {{ $t('close') }}
            </b-button>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { getDocument } from 'pdfjs-dist';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'pdfjs-dist/webpack.mjs';
import Tiff from 'tiff';
import * as paper from 'paper';
import * as Hammer from 'hammerjs';
import { v4 } from 'uuid';
import InspectionDetail from './InspectionDetail';
import InspectionList from './InspectionList';
import RatInspectionDetail from './RatInspectionDetail';
import Toolbar from './Toolbar';

import DIJVueConfigurations from '../../helpers/DIJVueConfigurations';
import {
  DELETE_BUBBLE,
  CREATE_BUBBLE,
  UPDATE_BUBBLE,
  SAVE_BUBBLES,
} from '../../store/bubbles/actions/actionTypes';
import { GET_JOB } from '../../store/jobs/actions/actionTypes';
import { GET_CURRENT_JOB } from '../../store/jobs/getters/getterTypes';
import { SET_CURRENT_TASK_DIAGRAM_SOURCE } from '../../store/tasks/mutations/mutationTypes';
import { SAVE_CURRENT_TASK_STATE } from '../../store/tasks/actions/actionTypes';
import { GET_LOADING_STATE } from '../../store/loading/getters/getterTypes';
import KeyHandlerMixin from '../../mixins/KeyHandlerMixin';

const uuidv4 = v4;

const EMPTY_MEASUREMENT_VALUE = {
  value: '',
  images: [],
  comment: '',
};

const EMPTY_VISUAL_MEASUREMENT_VALUE = {
  value: 'notCompleted',
  images: [],
  comment: '',
};

export default {
  name: 'diagram-builder',
  components: {
    InspectionList,
    InspectionDetail,
    RatInspectionDetail,
    Toolbar,
  },
  mixins: [KeyHandlerMixin],
  props: {
    taskProp: Object,
    tool: String,
    showList: {
      type: Boolean,
      default: true,
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    reportMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['cancel', 'ok'],
  watch: {
    isCustomerReport() {
      this.initializeMarkers();
    },
    selectedItem(newItem, old) {
      if (old.number && old.number !== newItem.number) {
        const isBubbleStillValid = this.inspections.find(
          (bubble) => bubble._id === old._id
        );
        if (isBubbleStillValid) {
          this.$store.dispatch(UPDATE_BUBBLE, old);
        }
      }
    },
  },

  computed: {
    task() {
      return this.taskProp;
    },
    job() {
      return this.$store.getters[GET_CURRENT_JOB];
    },
    isLoading() {
      return this.$store.getters[GET_LOADING_STATE];
    },
    isCustomerReport() {
      return !this.$store.state.ReportsModule.isInternalReport;
    },
    isMoveMeasurementVisible() {
      return (
        this.selectedItem &&
        ['length', 'line', 'arrow'].includes(this.selectedItem.type)
      );
    },
    inspectionListItems() {
      return this.inspections.filter((inspection) => inspection.type !== 'rat');
    },
    isTaskCompleted() {
      return this.task.workStatusCode
        ? this.task.workStatusCode.toString() === '2' && this.job.jobCompleted
        : false;
    },
  },

  data() {
    return {
      processingNewBubble: false,
      bubbleWasMoved: false,
      scope: null,
      mode: 'select',
      inspections: [],
      annotations: [],
      isItemDetailVisible: false,
      isItemRATVisible: false,
      selectedItem: {},
      isImageSelected: false,
      explicitMoveMeasurementMode: false,
      image: null,
      selectTool: null,
      moveTool: null,
      diameterTool: null,
      runoutTool: null,
      linearLengthTool: null,
      alignedLengthTool: null,
      visualTool: null,
      genericTool: null,
      freeFindingTool: null,
      textTool: null,
      ratTool: null,
      arrowTool: null,
      lineTool: null,
      isMouseButtonDown: false,
      isMovingImage: false,
      isMovingMarker: false,
      lengthStage: 0,
      lengthMode: 0,
      arrowStage: 0,
      lineStage: 0,
      multipleMeasurementSelector: null,
      switchBackTool: null,
      circleRadiusBubble: 15,
      fontSizeBubble: 18,
      xDeltas: [],
      yDeltas: [],
      isLoadingRatInspection: false,
      resizeTimeout: null,
    };
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);

    let imageSource;
    if (!this.isNewTask() && this.task.diagram.src) {
      // Use the new layout
      const lastPart = this.task.diagram.src.split('/').pop();
      const allButLast = this.task.diagram.src
        .split('/')
        .slice(0, -1)
        .join('/');
      imageSource = `${allButLast}/${encodeURIComponent(lastPart)}`;

      // For local env we need to point to the local s3
      if (imageSource && window.location.host.includes('localhost')) {
        imageSource = `http://localhost:4566/images${imageSource}`;
      }

      this.inspections = this.task.diagram.inspections || [];
      this.annotations = this.task.diagram.annotations || [];
    }

    if (imageSource) {
      this.isImageSelected = true;
    } else {
      this.isImageSelected = false;
    }

    const canvas = document.querySelector(
      `#dij-diagrambuilder-canvas-${this.task._id}`
    );

    const zoomOptions = document.querySelector(
      '.dij-diagrambuilder-zoomoption'
    );

    if (!this.showToolbar && !this.showList) {
      canvas.style.width = '100%';
    } else if (!this.showToolbar) {
      canvas.style.width = 'calc(100% - 225px)';
    } else if (!this.showList) {
      zoomOptions.style.left = '70px';
      canvas.style.left = '70px';
      canvas.style.width = 'calc(100% - 70px)';
    } else {
      zoomOptions.style.left = '70px';
      canvas.style.left = '70px';
      canvas.style.width = 'calc(100% - 225px - 70px)';
    }
    canvas.style.width = `${canvas.clientWidth}px`;
    canvas.style.height = `${canvas.clientHeight}px`;

    this.scope = new paper.PaperScope();
    this.scope.setup(this.$refs.canvas);

    if (this.task.zoomLevel) {
      const { circleRadiusBubble, fontSizeBubble } = this.task.zoomLevel;
      this.circleRadiusBubble = circleRadiusBubble;
      this.fontSizeBubble = fontSizeBubble;
    }

    if (this.isImageSelected) {
      this.image = new paper.Raster({
        crossOrigin: 'anonymous',
        source: imageSource,
      });
      this.image.onLoad = () => {
        this.image.position = this.scope.view.center;
        this.initializeMarkers();
        this.scope.view.zoom *= 0.75;
      };
      this.image.onError = () => {
        this.initializeMarkers();
        this.scope.view.zoom *= 0.75;
      };
    }

    if (this.task.zoomLevel) {
      const { imageZoom } = this.task.zoomLevel;
      this.scope.view.zoom = imageZoom;
    }

    // Create the tools
    this.initializeSelectTool();
    this.initializeMoveTool();
    this.initializeDiameterTool();
    this.initializeRunoutTool();
    this.initializeLinearLengthTool();
    this.initializeAlignedLengthTool();
    this.initializeVisualTool();
    this.initializeGenericTool();
    this.initializeRATTool();
    this.initializeTextTool();
    this.initializeArrowTool();
    this.initializeLineTool();
    this.initializeFreefindingTool();

    // Enable select tool
    this.enableSelectMode();

    let originalScale;
    let originalDistance;
    let lastPercentage = 1;

    const hammertime = new Hammer(this.$refs.canvas);
    // Enable pinch behavior support
    hammertime.get('pinch').set({ enable: true });

    // On start take the original distances and scale
    hammertime.on('pinchstart', (event) => {
      if (this.mode === 'select') {
        const dx = event.pointers[0].clientX - event.pointers[1].clientX;
        const dy = event.pointers[0].clientY - event.pointers[1].clientY;
        originalDistance = dx * dx + dy * dy;
        originalScale = lastPercentage;
      }
    });

    hammertime.on('pinch', (event) => {
      if (this.mode === 'select') {
        const dx = event.pointers[0].clientX - event.pointers[1].clientX;
        const dy = event.pointers[0].clientY - event.pointers[1].clientY;
        const distance = dx * dx + dy * dy;

        const scale =
          ((distance / originalDistance) * originalScale) / lastPercentage;

        const pinchCenter = new paper.Point(
          (event.pointers[0].clientX + event.pointers[1].clientX) / 2 -
            this.$refs.canvas.offsetLeft,
          (event.pointers[0].clientY + event.pointers[1].clientY) / 2 -
            this.$refs.canvas.offsetTop
        );

        this.scope.view.scale(scale, pinchCenter);

        lastPercentage = (distance / originalDistance) * originalScale;
      }
    });
    this.onUpdateCallback = {
      callback: this.closeInspection,
      isUpdate: true,
    };
    this.onCancelCallback = {
      callback: this.closeInspection,
      isCancel: true,
    };
  },
  methods: {
    // This refreshes the page when the window is resized, such as when a table user switches from landscape to portrait.
    handleResize() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isTablet = /ipad|android(?!.*mobile)|tablet/.test(userAgent);
      if (isTablet) {
        if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
        }
        this.resizeTimeout = setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    },

    importAccuScanValues(data) {
      this.inspections.sort((a, b) => a.number - b.number);

      let accuScanIndex = 1;
      this.inspections.forEach((inspection) => {
        if (inspection.type === 'runout' && !inspection.multipleMeasurements) {
          inspection.measurements[0].value = data[5][accuScanIndex];
          inspection.measurements[1].value = data[6][accuScanIndex];
          inspection.isInspector = true;
          accuScanIndex++;
        }
      });

      this.$store.dispatch(SAVE_BUBBLES, {
        taskId: this.task._id,
        data: this.inspections,
      });

      this.$buefy.snackbar.open({
        message: 'Accu Scan values imported successfully',
        type: 'is-success',
        position: 'is-top',
        duration: 5000,
      });
    },

    importLaserScanValues(data) {
      this.inspections.sort((a, b) => a.number - b.number);

      // Laser scan index starts from 17
      const laserScanStartIndex = 17;
      const valuesInspectionCSV = [];

      for (let row = laserScanStartIndex; row < data.length; row++) {
        const [objName, controlName, , measured] = data[row];

        const colName = objName.replace(/ /g, '');
        const patternRegex =
          /^(Diameter(\d+):Position(\d+)|Length(\d+):Position(\d+))$/i;
        const match = colName.match(patternRegex);
        if (match) {
          const regexControlName = /diameter|length/i;
          if (!regexControlName.test(controlName)) {
            this.$buefy.snackbar.open({
              message: `Control name should be Diameter or Length for ${colName}!`,
              type: 'is-danger',
              position: 'is-top',
              duration: 5000,
              pauseOnHover: true,
            });

            return;
          }

          // If it's Diameter
          if (match[2] && match[3]) {
            const bubbleNumber = parseInt(match[2], 10);
            const positionNumber = parseFloat(match[3]);

            this.pushInspetionsCSV(
              valuesInspectionCSV,
              {
                type: 'diameter',
                number: bubbleNumber,
              },
              {
                number: positionNumber,
                value: measured,
              }
            );

            // If it's Length
          } else if (match[4] && match[5]) {
            const bubbleNumber = parseInt(match[4], 10); // match[4];
            const positionNumber = parseFloat(match[5]); // match[5];
            this.pushInspetionsCSV(
              valuesInspectionCSV,
              {
                type: 'length',
                number: bubbleNumber,
              },
              {
                number: positionNumber,
                value: measured,
              }
            );
          }
        }
      }

      valuesInspectionCSV.sort((a, b) => a.number - b.number);
      // Check if the CSV file has the same number of inspections
      const inspectionsCount = this.inspections.reduce((count, item) => {
        if (item.type === 'diameter' || item.type === 'length') {
          count++;
        }
        return count;
      }, 0);

      const hasSameNumberOfInpections =
        valuesInspectionCSV.length === inspectionsCount;

      if (!hasSameNumberOfInpections) {
        this.$buefy.snackbar.open({
          message:
            'The CSV file does not contain the correct number of inspections!',
          type: 'is-danger',
          position: 'is-top',
          duration: 5000,
        });
        return;
      }

      let hasWrongNumberOfInspectionsPoints = false;
      this.inspections.forEach((inspection) => {
        if (inspection.type === 'diameter' || inspection.type === 'length') {
          const valueIndex = valuesInspectionCSV.findIndex((value) => {
            return (
              value.number === inspection.number &&
              value.type === inspection.type
            );
          });

          if (valueIndex === -1) {
            hasWrongNumberOfInspectionsPoints = inspection.number;
            return;
          }

          if (
            inspection.measurementCount !==
            valuesInspectionCSV[valueIndex].measurements.length
          ) {
            hasWrongNumberOfInspectionsPoints = inspection.number;
          }
        }
      });

      if (hasWrongNumberOfInspectionsPoints) {
        this.$buefy.snackbar.open({
          message: `The CSV file does not contain the correct number of points for inspection: ${hasWrongNumberOfInspectionsPoints}!`,
          type: 'is-danger',
          position: 'is-top',
          duration: 5000,
        });
        return;
      }

      // The csv file has the same number of inspections and points
      this.inspections.forEach((inspection) => {
        if (inspection.type === 'length' || inspection.type === 'diameter') {
          const valueIndex = valuesInspectionCSV.findIndex(
            (value) => value.number === inspection.number
          );

          inspection.measurements.forEach((m, indexM) => {
            m.value =
              valuesInspectionCSV[valueIndex].measurements[indexM].value;
          });
        }
      });

      this.$store.dispatch(SAVE_BUBBLES, {
        taskId: this.task._id,
        data: this.inspections,
      });

      this.$buefy.snackbar.open({
        message: 'Laser Scan values imported successfully',
        type: 'is-success',
        position: 'is-top',
        duration: 5000,
      });
    },

    pushInspetionsCSV(valuesInspectionCSV, inspection, positionValue) {
      const inspectionIndex = valuesInspectionCSV.findIndex(
        (value) =>
          value.number === inspection.number && value.type === inspection.type
      );
      if (inspectionIndex !== -1) {
        valuesInspectionCSV[inspectionIndex].measurements.push(positionValue);
      } else {
        valuesInspectionCSV.push({
          ...inspection,
          measurements: [positionValue],
        });
      }
    },

    getNextInspectionNumber() {
      const inspectionNumbers = this.inspections
        .filter(
          (inspection) =>
            !['text', 'arrow', 'line', 'rat'].includes(inspection.type)
        )
        .map((inspection) => inspection.number);

      const allNumbers = [...inspectionNumbers];

      if (allNumbers.length === 0) {
        return 1; // No inspections or annotations, start from 1
      }

      allNumbers.sort((a, b) => a - b);

      const firstNumber = allNumbers[0];

      // Check if the first number is greater than 1, if so, return 1
      if (firstNumber > 1) {
        return 1;
      }

      // Iterate through the numbers to find the first gap
      for (let i = 0; i < allNumbers.length - 1; i++) {
        if (allNumbers[i + 1] - allNumbers[i] > 1) {
          // Return the first missing number if a gap is found
          return allNumbers[allNumbers.length - 1] + 1;
        }
      }

      // If no gap is found, return the next number after the last one
      return allNumbers[allNumbers.length - 1] + 1;
    },

    initializeMarkers() {
      this.scope.activate();
      this.inspections.forEach((inspection) => {
        if (inspection.type === 'length') {
          this.makeLengthMarker(inspection);
        } else {
          this.makeSimpleMarker(inspection);
        }
      });
      this.annotations.forEach((annotation) => {
        if (annotation.type === 'text') {
          this.makeTextMarker(annotation);
        } else if (annotation.type === 'arrow') {
          this.makeArrowMarker(annotation);
        } else if (annotation.type === 'line') {
          this.makeLineMarker(annotation);
        }
      });
    },
    chooseDiagramToImport() {
      this.$refs.diagramChooser.click();
    },
    importDiagram(event) {
      // User clicked cancel
      if (event.target.files.length === 0) {
        return;
      }
      const file = event.target.files[0];
      const fileReader = new FileReader();
      if (file.type === 'application/pdf') {
        fileReader.onload = () => {
          getDocument(URL.createObjectURL(file)).promise.then((pdf) => {
            pdf.getPage(1).then((page) => {
              const viewport = page.getViewport({ scale: 1 });
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');
              canvas.height = viewport.height;
              canvas.width = viewport.width;
              const renderTask = page.render({
                canvasContext: context,
                viewport,
              });
              renderTask.promise.then(() => {
                const imageSource = new Image();
                imageSource.onload = () => {
                  this.isImageSelected = true;
                  if (this.image) {
                    this.image.remove();
                  }
                  this.image = new paper.Raster(imageSource);
                  this.scope.project.activeLayer.insertChild(0, this.image);
                  this.image.position = this.scope.view.center;
                };
                imageSource.src = canvas.toDataURL();
              });
            });
          });
        };
        fileReader.readAsArrayBuffer(file);
      } else if (file.type === 'image/tiff') {
        fileReader.onload = () => {
          // Convert .tiff files to image .jpg
          const arrayBuffer = fileReader.result;
          const tiff = new Tiff({
            buffer: arrayBuffer,
          });
          const canvas = tiff.toCanvas();

          const imageSource = new Image();

          imageSource.onload = () => {
            this.isImageSelected = true;
            if (this.image) {
              this.image.remove();
            }
            this.image = new paper.Raster(imageSource);
            this.scope.project.activeLayer.insertChild(0, this.image);
            this.image.position = this.scope.view.center;
          };

          // Get data from canvas
          imageSource.src = canvas.toDataURL();
        };
        fileReader.readAsArrayBuffer(file);
      } else {
        fileReader.onload = () => {
          const imageSource = new Image();
          imageSource.onload = () => {
            this.isImageSelected = true;
            if (this.image) {
              this.image.remove();
            }
            this.image = new paper.Raster(imageSource);
            this.scope.project.activeLayer.insertChild(0, this.image);
            this.image.position = this.scope.view.center;
          };
          imageSource.src = fileReader.result;

          this.saveImageToS3({ file, src: imageSource.src });
        };

        fileReader.readAsDataURL(file);
      }

      // Clear the file list to allow the user to repeat the upload
      event.target.value = '';
    },
    async uploadToS3(file, path) {
      const dijConfigurations = new DIJVueConfigurations();
      return dijConfigurations.uploadFileToS3(file, file.name, path);
    },
    async saveImageToS3(data) {
      const path = `uploads/products/${new Date().getFullYear()}/${
        this.task._id
      }`;
      const url = await this.uploadToS3(data.file, path);

      this.$store.commit(SET_CURRENT_TASK_DIAGRAM_SOURCE, url);
      this.$store.dispatch(SAVE_CURRENT_TASK_STATE);
    },
    enableMode(mode) {
      if (!this[mode]) return;

      this[mode]();
    },
    enableSelectMode() {
      this.explicitMoveMeasurementMode = false;
      this.isItemDetailVisible = false;
      this.mode = 'select';
      this.selectTool.activate();
    },
    enableMoveMode() {
      this.isItemDetailVisible = false;
      this.mode = 'move';
      this.moveTool.activate();
    },
    enableMoveMeasurementMode() {
      this.explicitMoveMeasurementMode = !this.explicitMoveMeasurementMode;

      this.isItemDetailVisible = false;
      this.mode = 'select';
      this.selectTool.activate();
    },
    enableDiameterMode() {
      this.isItemDetailVisible = false;
      this.mode = 'diameter';
      this.diameterTool.activate();
    },
    enableRunoutMode() {
      this.isItemDetailVisible = false;
      this.mode = 'runout';
      this.runoutTool.activate();
    },
    enableLinearLengthMode() {
      this.isItemDetailVisible = false;
      this.mode = 'linearLength';
      this.linearLengthTool.activate();
    },
    enableAlignedLengthMode() {
      this.isItemDetailVisible = false;
      this.mode = 'alignedLength';
      this.alignedLengthTool.activate();
    },
    enableVisualMode() {
      this.isItemDetailVisible = false;
      this.mode = 'visual';
      this.visualTool.activate();
    },
    enableGenericMode() {
      this.isItemDetailVisible = false;
      this.mode = 'generic';
      this.genericTool.activate();
    },
    enableFreefindingMode() {
      if (this.mode !== 'freefinding') {
        this.isItemDetailVisible = false;
        this.mode = 'freefinding';
        this.freeFindingTool.activate();
      } else {
        this.enableSelectMode();
      }
    },
    enableTextMode() {
      this.isItemDetailVisible = false;
      this.mode = 'text';
      this.textTool.activate();
    },
    enableRATMode() {
      if (this.mode !== 'rat') {
        this.isItemRATVisible = false;
        this.mode = 'rat';
        this.ratTool.activate();
      } else {
        this.enableSelectMode();
      }
    },
    enableArrowMode() {
      this.isItemDetailVisible = false;
      this.mode = 'arrow';
      this.arrowTool.activate();
    },
    enableLineMode() {
      this.isItemDetailVisible = false;
      this.mode = 'line';
      this.lineTool.activate();
    },
    replaceImage() {
      this.isItemDetailVisible = false;
      this.$refs.diagramChooser.click();
    },
    zoomDefault() {
      this.scope.view.zoom = 1;
      this.onZoomChange();
    },
    zoomIn() {
      this.scope.view.zoom *= 1.2;
      this.onZoomChange();
    },
    zoomDefaultBubble() {
      this.circleRadiusBubble = 15;
      this.fontSizeBubble = 18;
      this.initializeMarkers();
      this.onZoomChange();
    },
    zoomInBubble() {
      this.circleRadiusBubble += 2;
      this.fontSizeBubble += 2;
      this.initializeMarkers();
      this.onZoomChange();
    },
    zoomOutBubble() {
      this.circleRadiusBubble -= 2;
      this.fontSizeBubble -= 2;
      this.initializeMarkers();
      this.onZoomChange();
    },
    zoomOut() {
      this.scope.view.zoom *= 0.8;
      this.onZoomChange();
    },
    onZoomChange() {
      const imageZoom = this.scope.view.zoom;
      const { circleRadiusBubble, fontSizeBubble } = this;
      this.$emit('zoomChange', {
        imageZoom,
        circleRadiusBubble,
        fontSizeBubble,
      });
    },
    cancel() {
      this.$emit('cancel');
    },
    ok() {
      const { canvas } = this.$refs;
      this.$emit('ok', canvas);
    },

    async deleteInspection() {
      // To delete the rat entry
      if (this.selectedItem.type === 'rat') {
        await this.$store.dispatch(GET_JOB, this.$route.params.id);
        const job = this.$store.state.JobsModule.currentJob;

        let row = null;

        if (job.repairAssessment)
          row = job.repairAssessment.find(
            (rat) => rat.id === this.selectedItem.ratRowID
          );

        if (row) {
          this.$store.dispatch('deleteRepairAssessmentRow', {
            job,
            row,
            tool: this.tool,
          });
        }
      }

      this.$store.dispatch(DELETE_BUBBLE, this.selectedItem._id);

      if (this.selectedItem.type === 'rat') {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: 'RAT entry successfully deleted',
          type: 'is-info',
          position: 'is-bottom-left',
          queue: false,
        });
      } else {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: `${
            this.selectedItem.type.charAt(0).toUpperCase() +
            this.selectedItem.type.slice(1)
          } deleted successfully`,
          type: 'is-info',
          position: 'is-bottom-left',
          queue: false,
        });
      }

      this.inspections = this.inspections.filter(
        (inspection) => inspection !== this.selectedItem
      );

      this.annotations = this.annotations.filter(
        (inspection) => inspection !== this.selectedItem
      );

      this.selectedItem.marker.remove();
      this.isItemDetailVisible = false;
      this.isItemRATVisible = false;
    },
    closeInspection() {
      this.isItemDetailVisible = false;
      this.isItemRATVisible = false;
      this.$store.dispatch(UPDATE_BUBBLE, this.selectedItem);
    },

    async saveInspectionRat() {
      this.isLoadingRatInspection = true;
      try {
        if (!this.selectedItem.ratRowID) {
          const ratRowID = await this.addRATRow(
            this.$refs.refRatDetail.isMinor
          );
          this.selectedItem.ratRowID = ratRowID;
        }

        await this.$store.dispatch(GET_JOB, this.$route.params.id);
        const job = this.$store.state.JobsModule.currentJob;
        let row = {};
        if (job.repairAssessment && job.repairAssessment.length > 0) {
          const rowRat = job.repairAssessment.find(
            (rat) => rat.id === this.selectedItem.ratRowID
          );
          if (rowRat) row = rowRat;
        }

        if (this.$refs.refRatDetail.isMinor) {
          row.assembly = this.selectedItem.assembly
            ? this.selectedItem.assembly
            : '';
          row.itemNumber = this.selectedItem.itemNumber
            ? this.selectedItem.itemNumber
            : '';
          row.partName = this.selectedItem.partName
            ? this.selectedItem.partName
            : '';
          row.partNumber = this.selectedItem.partNumber;
          row.quantity = this.selectedItem.quantity;
          row.reuse = this.selectedItem.reuse;
          row.repair = this.selectedItem.repair;
          row.replace = this.selectedItem.replace;
          row.comments = this.selectedItem.comments;
          row.isMinor = true;
        } else {
          row.level = this.selectedItem.level ? this.selectedItem.level : 1;
          row.assembly = this.selectedItem.assembly;
          row.itemNumber = this.selectedItem.itemNumber;
          row.partName = this.selectedItem.partName;
          row.partNumber = this.selectedItem.partNumber;
          row.quantity = this.selectedItem.quantity;
          row.visual = this.selectedItem.visual;
          row.dim = this.selectedItem.dim;
          row.ndt = this.selectedItem.ndt;
          row.reuse = this.selectedItem.reuse;
          row.repair = this.selectedItem.repair;
          row.replace = this.selectedItem.replace;
          row.missing = this.selectedItem.missing;
          row.summary = this.selectedItem.summary;
          row.repairScope = this.selectedItem.repairScope;
          row.estimatedLabor = this.selectedItem.estimatedLabor;
          row.received = this.selectedItem.received;
          row.isMinor = false;
        }

        row.bubbleId = this.selectedItem._id;

        await this.$store.dispatch(UPDATE_BUBBLE, this.selectedItem);
        await this.$store.dispatch('updateRepairAssessmentRow', {
          job,
          row,
          tool: this.tool,
        });
        await this.$store.dispatch('unlockRepairAssessmentRow', {
          job,
          row,
          tool: this.tool,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoadingRatInspection = false;
        this.closeInspection();
      }
    },
    async addNewInspection() {
      this.processingNewBubble = true;
      const createdBubble = await this.$store.dispatch(CREATE_BUBBLE, {
        taskId: this.task._id,
        bubble: this.selectedItem,
      });
      this.selectedItem._id = createdBubble._id;
      this.inspections.push(this.selectedItem);
      this.processingNewBubble = false;
    },
    initializeSelectTool() {
      this.selectTool = new paper.Tool();
      this.selectTool.onMouseDown = (event) => {
        if (this.isTaskCompleted) {
          this.taskCompletedError();
          return;
        }

        // Ignore image hits
        if (this.isSelectingMarker(event)) {
          this.handleOnMouseDownMarkerSelection(event);
        } else {
          this.isMovingImage = true;
        }
      };
      let initialPoint;
      // Don't allow drag behavior in inspector mode
      this.selectTool.onMouseDrag = (event) => {
        this.bubbleWasMoved = true;
        if (this.isMovingImage) {
          this.moveImageTo(event.lastPoint, event.point);
        } else if (!this.isInInspectorMode() && this.isMouseButtonDown) {
          // Save the first point as the initial point
          if (!initialPoint) {
            initialPoint = event.point;
          }
          if (
            this.isMovingMarker ||
            this.isOutsideMoveDeadzone(initialPoint, event.point)
          ) {
            this.isMovingMarker = true;
            if (this.selectedItem.type === 'length') {
              if (this.explicitMoveMeasurementMode) {
                const dx =
                  event.point.x -
                  this.selectedItem.marker.children[1].position.x;
                const dy =
                  event.point.y -
                  this.selectedItem.marker.children[1].position.y;
                const actualPoint = {
                  x: this.selectedItem.marker.position.x + dx,
                  y: this.selectedItem.marker.position.y + dy,
                };
                this.selectedItem.marker.position = actualPoint;

                this.selectedItem.firstPoint = this.getImagePoint(
                  this.selectedItem.marker.children[0].segments[0].point
                );
                this.selectedItem.secondPoint = this.getImagePoint(
                  this.selectedItem.marker.children[0].segments[1].point
                );
                this.selectedItem.thirdPoint = this.getImagePoint(
                  this.selectedItem.marker.children[0].segments[2].point
                );
                this.selectedItem.fourthPoint = this.getImagePoint(
                  this.selectedItem.marker.children[0].segments[3].point
                );
                this.selectedItem.point = this.getImagePoint(
                  this.selectedItem.marker.children[1].position
                );
              } else {
                const initialLengthPoint =
                  this.selectedItem.marker.children[0].segments[0].point;
                const finalPoint =
                  this.selectedItem.marker.children[0].segments[3].point;
                if (this.selectedItem.lengthType === 'aligned') {
                  // Make line and circle
                  const circleRadius = 15;
                  const points = this.calculateParallelPoints(
                    initialLengthPoint,
                    finalPoint,
                    event.point
                  );
                  const path = new paper.Path(
                    initialLengthPoint,
                    points.middlePoint1,
                    points.middlePoint2,
                    finalPoint
                  );
                  // Convert middle points to Paper.js Point objects for easy calculations
                  const p1 = new paper.Point(
                    points.middlePoint1.x,
                    points.middlePoint1.y
                  );
                  const p2 = new paper.Point(
                    points.middlePoint2.x,
                    points.middlePoint2.y
                  );
                  const mousePoint = event.point;
                  // Calculate vectors
                  const lineVector = p2.subtract(p1);
                  const mouseVector = mousePoint.subtract(p1);
                  // Project mouseVector onto lineVector
                  let projection = lineVector
                    .normalize()
                    .multiply(mouseVector.dot(lineVector.normalize()));
                  // Check if projection exceeds the line segment
                  if (projection.length > lineVector.length) {
                    projection = lineVector; // Snap to p2 if beyond
                  } else if (projection.dot(lineVector) < 0) {
                    projection = new paper.Point(0, 0); // Snap to p1 if before
                  }
                  // Calculate new circle position
                  const circlePosition = p1.add(projection);
                  const circle = new paper.Path.Circle(
                    circlePosition,
                    circleRadius
                  );
                  this.lengthMode = 0;
                  path.strokeColor = '#aaa';
                  path.strokeWidth = 4;
                  circle.strokeWidth = 0;
                  circle.fillColor = '#aaa';
                  // Make label
                  const label = new paper.PointText();
                  label.fillColor = 'white';
                  label.content = this.selectedItem.number;
                  label.fontFamily = 'Siemens Roman';
                  label.fontSize = 18;
                  label.position = circlePosition;
                  // Remove previous marker and create new one
                  this.selectedItem.marker.remove();
                  this.selectedItem.marker = new paper.Group(
                    path,
                    circle,
                    label
                  );
                } else {
                  this.addDelta(event.delta);
                  const mode = this.determineMode(event);
                  const middlePoints = this.calculateMiddlePoints(
                    mode,
                    initialLengthPoint,
                    finalPoint,
                    event.point
                  );
                  const { path, circle, circlePosition } =
                    this.createPathAndCircle(
                      initialLengthPoint,
                      finalPoint,
                      middlePoints,
                      event.point,
                      mode
                    );
                  // Set path properties
                  path.strokeColor = '#aaa';
                  path.strokeWidth = 4;
                  // Set circle properties
                  circle.strokeWidth = 0;
                  circle.fillColor = '#aaa';
                  // Make label
                  const label = new paper.PointText({
                    fillColor: 'white',
                    content: this.selectedItem.number,
                    fontFamily: 'Siemens Roman',
                    fontSize: 18,
                    position: circlePosition,
                  });
                  // Update length mode based on current mode
                  this.lengthMode = mode === 'horizontal' ? 0 : 1;
                  // Remove previous marker and create new one
                  this.selectedItem.marker.remove();
                  this.selectedItem.marker = new paper.Group(
                    path,
                    circle,
                    label
                  );
                }
                this.selectedItem.secondPoint = this.getImagePoint(
                  this.selectedItem.marker.children[0].segments[1].point
                );
                this.selectedItem.thirdPoint = this.getImagePoint(
                  this.selectedItem.marker.children[0].segments[2].point
                );
                this.selectedItem.point = this.getImagePoint(
                  this.selectedItem.marker.children[1].position
                );
              }
            } else if (['arrow', 'line'].includes(this.selectedItem.type)) {
              if (this.explicitMoveMeasurementMode) {
                this.selectedItem.marker.position = event.point;
                this.selectedItem.point = this.getImagePoint(
                  this.selectedItem.marker.children[0].segments[1].point
                );
                const startPoint =
                  this.selectedItem.marker.children[0].segments[0].point;
                this.selectedItem.startPoint = this.getImagePoint(startPoint);
              } else {
                const initialArrowPoint =
                  this.selectedItem.marker.children[0].segments[0].point;

                this.selectedItem.marker.remove();
                const path = new paper.Path(initialArrowPoint, event.point);
                path.strokeColor = '#aaa';
                path.strokeWidth = 4;
                this.selectedItem.marker = new paper.Group(path);

                this.selectedItem.point = this.getImagePoint(
                  this.selectedItem.marker.children[0].segments[1].point
                );
              }
            } else if (this.selectedItem.type === 'text') {
              this.selectedItem.marker.position = event.point;
              this.selectedItem.point = this.getImagePoint(
                this.selectedItem.marker.children[0].position
              );
            } else {
              this.selectedItem.marker.position = event.point;
              this.selectedItem.point = this.getImagePoint(
                this.selectedItem.marker.children[1].segments[2].point
              );
            }
          }
        }
      };
      this.selectTool.onMouseUp = async (event) => {
        initialPoint = null;
        const wasMovingImage = this.isMovingImage;
        this.isMovingImage = false;
        this.isMouseButtonDown = false;
        if (!wasMovingImage) {
          if (this.showToolbar && this.selectedItem) {
            const windowPoint = {
              x: event.event.clientX,
              y: event.event.clientY,
            };

            if (!this.bubbleWasMoved) {
              this.showInspectionDetail(windowPoint);
            }

            if (this.selectedItem.type === 'arrow') {
              this.makeArrowMarker(this.selectedItem);
            }

            // If there a switchBackTool, switch back to it
            if (this.switchBackTool) {
              this.switchBackTool.activate();
              this.switchBackTool = null;
            }
          } else if (
            this.isInInspectorMode() &&
            this.selectedItem &&
            this.showList
          ) {
            this.$refs.list.selectInspection(this.selectedItem);
            const windowPoint = {
              x: event.event.clientX,
              y: event.event.clientY,
            };

            this.showInspectionDetail(windowPoint);
          } else if (
            this.selectedItem &&
            this.selectedItem.type === 'rat' &&
            !this.bubbleWasMoved
          ) {
            const windowPoint = {
              x: event.event.clientX,
              y: event.event.clientY,
            };

            this.showInspectionDetail(windowPoint);
          } else if (
            this.isInInspectorMode() &&
            this.selectedItem &&
            this.selectedItem.type !== 'rat'
          ) {
            this.selectedItem = null;
          }
        }
        if (this.bubbleWasMoved) {
          await this.$store.dispatch(UPDATE_BUBBLE, this.selectedItem);
        }

        this.bubbleWasMoved = false;
      };
    },
    initializeMoveTool() {
      this.moveTool = new paper.Tool();
      this.moveTool.onMouseDown = () => {
        this.isMouseButtonDown = true;
      };
      this.moveTool.onMouseUp = () => {
        this.isMouseButtonDown = false;
      };
      this.moveTool.onMouseDrag = (event) => {
        if (this.isMouseButtonDown) {
          this.moveImageTo(event.lastPoint, event.point);
        }
      };
    },
    showProcessingBubblePopup() {
      this.$buefy.snackbar.open({
        duration: 5000,
        message: 'Hold on! The system is handling the last operation.',
        pauseOnHover: true,
        type: 'is-link',
        queue: false,
      });
    },
    initializeDiameterTool() {
      this.diameterTool = new paper.Tool();
      this.diameterTool.onMouseDown = async (event) => {
        if (this.isTaskCompleted) {
          this.taskCompletedError();
          return;
        }
        if (this.isSelectingMarker(event)) {
          this.delegateToSelectTool(event);
        } else {
          if (this.processingNewBubble) {
            this.showProcessingBubblePopup();
            return;
          }
          this.selectedItem = {
            type: 'diameter',
            number: this.getNextInspectionNumber(),
            unit: 'in',
            expectedValue: '0.0000',
            decimalPlaces: 4,
            toleranceType: 'normal',
            positiveTolerance: '0.0000',
            negativeTolerance: '0.0000',
            orientation: 'up',
            measurementCount: 1,
            measurements: [EMPTY_MEASUREMENT_VALUE],
            multipleMeasurements: false,
            point: this.getImagePoint(event.point),
          };

          // Copy the data from the last marker of the same type
          const diameterInspections = this.inspections.filter(
            (inspection) => inspection.type === 'diameter'
          );
          if (diameterInspections.length > 0) {
            const lastDiameterInspection =
              diameterInspections[diameterInspections.length - 1];
            this.copyMarkerData(lastDiameterInspection, this.selectedItem);
          }

          this.addNewInspection();

          this.makeSimpleMarker(this.selectedItem);

          const windowPoint = {
            x: event.event.clientX,
            y: event.event.clientY,
          };
          this.showInspectionDetail(windowPoint);
        }
      };
    },
    initializeRunoutTool() {
      this.runoutTool = new paper.Tool();
      this.runoutTool.onMouseDown = async (event) => {
        if (this.isTaskCompleted) {
          this.taskCompletedError();
          return;
        }
        if (this.isSelectingMarker(event)) {
          this.delegateToSelectTool(event);
        } else {
          if (this.processingNewBubble) {
            this.showProcessingBubblePopup();
            return;
          }
          this.selectedItem = {
            id: uuidv4(),
            type: 'runout',
            number: this.getNextInspectionNumber(),
            unit: 'in',
            tolerance: '0.0000',
            decimalPlaces: 4,
            directionalUnit: 'deg',
            direction: '0',
            orientation: 'up',
            measurementCount: 2,
            measurements: [EMPTY_MEASUREMENT_VALUE, EMPTY_MEASUREMENT_VALUE],
            multipleMeasurements: false,
            point: this.getImagePoint(event.point),
          };

          // Copy the data from the last marker of the same type
          const runoutInspections = this.inspections.filter(
            (inspection) => inspection.type === 'runout'
          );
          if (runoutInspections.length > 0) {
            const lastRunoutInspection =
              runoutInspections[runoutInspections.length - 1];
            this.copyMarkerData(lastRunoutInspection, this.selectedItem);
          }

          this.addNewInspection();
          this.makeSimpleMarker(this.selectedItem);

          const windowPoint = {
            x: event.event.clientX,
            y: event.event.clientY,
          };
          this.showInspectionDetail(windowPoint);
        }
      };
    },

    calculateMiddlePoints(mode, initialPoint, finalPoint, eventPoint) {
      if (mode === 'horizontal') {
        return [
          new paper.Point(initialPoint.x, eventPoint.y),
          new paper.Point(finalPoint.x, eventPoint.y),
        ];
      }

      // mode === 'vertical'
      return [
        new paper.Point(eventPoint.x, initialPoint.y),
        new paper.Point(eventPoint.x, finalPoint.y),
      ];
    },

    createPathAndCircle(
      initialPoint,
      finalPoint,
      middlePoints,
      eventPoint,
      mode
    ) {
      const path = new paper.Path(initialPoint, ...middlePoints, finalPoint);
      const circleRadius = 15;
      let circlePosition;

      if (mode === 'horizontal') {
        circlePosition = new paper.Point(
          (initialPoint.x + finalPoint.x) / 2,
          eventPoint.y
        );
      } else {
        // mode === 'vertical'
        circlePosition = new paper.Point(
          eventPoint.x,
          (initialPoint.y + finalPoint.y) / 2
        );
      }

      const circle = new paper.Path.Circle(circlePosition, circleRadius);

      return { path, circle, circlePosition };
    },
    /* eslint-disable */
    addDelta(delta) {
      // Add the new delta
      this.xDeltas.push(delta.x);
      this.yDeltas.push(delta.y);

      if (this.xDeltas.length > 10) this.xDeltas.shift();
      if (this.yDeltas.length > 10) this.yDeltas.shift();
    },
    getAverageDelta() {
      // Calculate the average for x and y deltas
      const avgX =
        this.xDeltas.reduce((a, b) => a + b, 0) / this.xDeltas.length;
      const avgY =
        this.yDeltas.reduce((a, b) => a + b, 0) / this.yDeltas.length;

      return { x: avgX, y: avgY };
    },
    determineMode() {
      const { x, y } = this.getAverageDelta();
      if (Math.abs(x) > Math.abs(y)) {
        return 'vertical';
      } else {
        return 'horizontal';
      }
    },
    taskCompletedError() {
      this.$buefy.toast.open({
        duration: 3000,
        message: this.$t('task_completed_message'),
        type: 'is-warning',
      });
    },
    initializeLinearLengthTool() {
      this.linearLengthTool = new paper.Tool();
      this.linearLengthTool.onMouseDown = async (event) => {
        if (this.isTaskCompleted) {
          this.taskCompletedError();
          return;
        }

        if (this.lengthStage === 0) {
          if (this.isSelectingMarker(event)) {
            this.delegateToSelectTool(event);
          } else {
            if (this.processingNewBubble) {
              this.showProcessingBubblePopup();
              return;
            }
            // Make line
            const path = new paper.Path(event.point, event.point);
            path.strokeColor = '#aaa';
            path.strokeWidth = 4;
            // Make circle
            const circleRadius = 15;
            const circle = new paper.Path.Circle(event.point, circleRadius);
            circle.strokeWidth = 0;
            circle.fillColor = '#aaa';
            // Make Label
            const label = new paper.PointText();
            label.fillColor = 'white';
            label.content = this.getNextInspectionNumber();
            label.fontFamily = 'Siemens Roman';
            label.fontSize = 18;
            label.position = event.point;

            // Group all together
            const marker = new paper.Group(path, circle, label);

            this.selectedItem = {
              id: uuidv4(),
              type: 'length',
              number: this.getNextInspectionNumber(),
              unit: 'in',
              expectedValue: '0.0000',
              decimalPlaces: 4,
              toleranceType: 'normal',
              positiveTolerance: '0.0000',
              negativeTolerance: '0.0000',
              measurementCount: 1,
              measurements: [EMPTY_MEASUREMENT_VALUE],
              multipleMeasurements: false,
              marker,
            };

            // Copy the data from the last marker of the same type
            const lengthInspections = this.inspections.filter(
              (inspection) => inspection.type === 'length'
            );
            if (lengthInspections.length > 0) {
              const lastLengthInspection =
                lengthInspections[lengthInspections.length - 1];
              this.copyMarkerData(lastLengthInspection, this.selectedItem);
            }

            // Move to stage 1
            this.lengthStage = 1;
          }
        } else if (this.lengthStage === 1) {
          const initialPoint =
            this.selectedItem.marker.children[0].segments[0].point;
          const finalPoint =
            this.selectedItem.marker.children[0].segments[1].point;

          // Make line
          const path = new paper.Path(
            initialPoint,
            event.point,
            event.point,
            finalPoint
          );
          path.strokeColor = '#aaa';
          path.strokeWidth = 4;
          // Make circle
          const circleRadius = 15;
          const circleX = (initialPoint.x + finalPoint.x) / 2;
          const circleY = (initialPoint.y + finalPoint.y) / 2;
          const circlePosition = { x: circleX, y: circleY };
          const circle = new paper.Path.Circle(circlePosition, circleRadius);
          circle.strokeWidth = 0;
          circle.fillColor = '#aaa';
          // Make label
          const label = new paper.PointText();
          label.fillColor = 'white';
          label.content = this.getNextInspectionNumber();
          label.fontFamily = 'Siemens Roman';
          label.fontSize = 18;
          label.position = circlePosition;

          // Remove previous marker and create new one
          this.selectedItem.marker.remove();
          this.selectedItem.marker = new paper.Group(path, circle, label);

          // Move to stage 2
          this.lengthStage = 2;
        } else if (this.lengthStage === 2) {
          const markerPosition = this.selectedItem.marker.children[1].position;

          this.selectedItem.firstPoint = this.getImagePoint(
            this.selectedItem.marker.children[0].segments[0].point
          );
          this.selectedItem.secondPoint = this.getImagePoint(
            this.selectedItem.marker.children[0].segments[1].point
          );
          this.selectedItem.thirdPoint = this.getImagePoint(
            this.selectedItem.marker.children[0].segments[2].point
          );
          this.selectedItem.fourthPoint = this.getImagePoint(
            this.selectedItem.marker.children[0].segments[3].point
          );
          this.selectedItem.point = this.getImagePoint(markerPosition);

          this.addNewInspection();

          const windowPoint = {
            x: event.event.clientX,
            y: event.event.clientY,
          };
          this.showInspectionDetail(windowPoint);

          // Move to stage 0
          this.lengthStage = 0;
        }
      };
      this.linearLengthTool.onMouseMove = (event) => {
        if (this.lengthStage === 1) {
          const initialPoint =
            this.selectedItem.marker.children[0].segments[0].point;
          const finalPoint = event.point;

          // Make line
          const path = new paper.Path(initialPoint, finalPoint);
          path.strokeColor = '#aaa';
          path.strokeWidth = 4;
          // Make circle
          const circleRadius = 15;
          const circleX = (initialPoint.x + finalPoint.x) / 2;
          const circleY = (initialPoint.y + finalPoint.y) / 2;
          const circlePosition = { x: circleX, y: circleY };
          const circle = new paper.Path.Circle(circlePosition, circleRadius);
          circle.strokeWidth = 0;
          circle.fillColor = '#aaa';
          // Make label
          const label = new paper.PointText();
          label.fillColor = 'white';
          label.content = this.getNextInspectionNumber();
          label.fontFamily = 'Siemens Roman';
          label.fontSize = 18;
          label.position = circlePosition;

          // Remove previous marker and create new one
          this.selectedItem.marker.remove();
          this.selectedItem.marker = new paper.Group(path, circle, label);
        } else if (this.lengthStage === 2) {
          const initialPoint =
            this.selectedItem.marker.children[0].segments[0].point;
          const finalPoint =
            this.selectedItem.marker.children[0].segments[3].point;

          const dx = Math.abs(event.lastPoint.x - event.point.x);
          const dy = Math.abs(event.lastPoint.y - event.point.y);
          this.addDelta(event.delta);
          const mode = this.determineMode(event);
          const middlePoints = this.calculateMiddlePoints(
            mode,
            initialPoint,
            finalPoint,
            event.point
          );
          const { path, circle, circlePosition } = this.createPathAndCircle(
            initialPoint,
            finalPoint,
            middlePoints,
            event.point,
            mode
          );

          // Set path properties
          path.strokeColor = '#aaa';
          path.strokeWidth = 4;

          // Set circle properties
          circle.strokeWidth = 0;
          circle.fillColor = '#aaa';

          // Make label
          const label = new paper.PointText({
            fillColor: 'white',
            content: this.getNextInspectionNumber(),
            fontFamily: 'Siemens Roman',
            fontSize: 18,
            position: circlePosition,
          });

          // Update length mode based on current mode
          this.lengthMode = mode === 'horizontal' ? 0 : 1;

          // Remove previous marker and create new one
          this.selectedItem.marker.remove();
          this.selectedItem.marker = new paper.Group(path, circle, label);
        }
      };
    },
    calculateParallelPoints(initialPoint, finalPoint, mousePoint) {
      // Calculate unit direction vector for the line
      const lineDirection = finalPoint.subtract(initialPoint).normalize();

      // Calculate perpendicular vector to the line
      const perpendicular = new paper.Point(lineDirection.y, -lineDirection.x);

      // Determine the side of the mouse relative to the line
      const vectorToMouse = mousePoint.subtract(initialPoint);

      // Calculate distance along the perpendicular
      // This calculation considers both the mouse's distance to the line and its side
      const distanceToLine = vectorToMouse.dot(perpendicular);
      const distance = distanceToLine;

      // Calculate the middle points offset by the distance along the perpendicular
      const middlePoint1 = initialPoint.add(perpendicular.multiply(distance));
      const middlePoint2 = finalPoint.add(perpendicular.multiply(distance));

      return { middlePoint1, middlePoint2 };
    },

    initializeAlignedLengthTool() {
      this.alignedLengthTool = new paper.Tool();
      this.alignedLengthTool.onMouseDown = async (event) => {
        if (this.isTaskCompleted) {
          this.taskCompletedError();
          return;
        }

        if (this.lengthStage === 0) {
          if (this.isSelectingMarker(event)) {
            this.delegateToSelectTool(event);
          } else {
            if (this.processingNewBubble) {
              this.showProcessingBubblePopup();
              return;
            }
            // Make line
            const path = new paper.Path(event.point, event.point);
            path.strokeColor = '#aaa';
            path.strokeWidth = 4;
            // Make circle
            const circleRadius = 15;
            const circle = new paper.Path.Circle(event.point, circleRadius);
            circle.strokeWidth = 0;
            circle.fillColor = '#aaa';
            // Make Label
            const label = new paper.PointText();
            label.fillColor = 'white';
            label.content = this.getNextInspectionNumber();
            label.fontFamily = 'Siemens Roman';
            label.fontSize = 18;
            label.position = event.point;

            // Group all together
            const marker = new paper.Group(path, circle, label);

            this.selectedItem = {
              id: uuidv4(),
              type: 'length',
              lengthType: 'aligned',
              number: this.getNextInspectionNumber(),
              unit: 'in',
              expectedValue: '0.0000',
              decimalPlaces: 4,
              toleranceType: 'normal',
              positiveTolerance: '0.0000',
              negativeTolerance: '0.0000',
              measurementCount: 1,
              measurements: [EMPTY_MEASUREMENT_VALUE],
              multipleMeasurements: false,
              marker,
            };

            // Copy the data from the last marker of the same type
            const lengthInspections = this.inspections.filter(
              (inspection) => inspection.type === 'length'
            );
            if (lengthInspections.length > 0) {
              const lastLengthInspection =
                lengthInspections[lengthInspections.length - 1];
              this.copyMarkerData(lastLengthInspection, this.selectedItem);
            }

            // Move to stage 1
            this.lengthStage = 1;
          }
        } else if (this.lengthStage === 1) {
          const initialPoint =
            this.selectedItem.marker.children[0].segments[0].point;
          const finalPoint =
            this.selectedItem.marker.children[0].segments[1].point;

          // Make line
          const path = new paper.Path(
            initialPoint,
            event.point,
            event.point,
            finalPoint
          );
          path.strokeColor = '#aaa';
          path.strokeWidth = 4;
          // Make circle
          const circleRadius = 15;
          const circleX = (initialPoint.x + finalPoint.x) / 2;
          const circleY = (initialPoint.y + finalPoint.y) / 2;
          const circlePosition = { x: circleX, y: circleY };
          const circle = new paper.Path.Circle(circlePosition, circleRadius);
          circle.strokeWidth = 0;
          circle.fillColor = '#aaa';
          // Make label
          const label = new paper.PointText();
          label.fillColor = 'white';
          label.content = this.getNextInspectionNumber();
          label.fontFamily = 'Siemens Roman';
          label.fontSize = 18;
          label.position = circlePosition;

          // Remove previous marker and create new one
          this.selectedItem.marker.remove();
          this.selectedItem.marker = new paper.Group(path, circle, label);

          // Move to stage 2
          this.lengthStage = 2;
        } else if (this.lengthStage === 2) {
          const markerPosition = this.selectedItem.marker.children[1].position;

          this.selectedItem.firstPoint = this.getImagePoint(
            this.selectedItem.marker.children[0].segments[0].point
          );
          this.selectedItem.secondPoint = this.getImagePoint(
            this.selectedItem.marker.children[0].segments[1].point
          );
          this.selectedItem.thirdPoint = this.getImagePoint(
            this.selectedItem.marker.children[0].segments[2].point
          );
          this.selectedItem.fourthPoint = this.getImagePoint(
            this.selectedItem.marker.children[0].segments[3].point
          );
          this.selectedItem.point = this.getImagePoint(markerPosition);

          this.addNewInspection();

          const windowPoint = {
            x: event.event.clientX,
            y: event.event.clientY,
          };
          this.showInspectionDetail(windowPoint);

          // Move to stage 0
          this.lengthStage = 0;
        }
      };
      this.alignedLengthTool.onMouseMove = (event) => {
        if (this.lengthStage === 1) {
          const initialPoint =
            this.selectedItem.marker.children[0].segments[0].point;
          const finalPoint = event.point;

          // Make line
          const path = new paper.Path(initialPoint, finalPoint);
          path.strokeColor = '#aaa';
          path.strokeWidth = 4;
          // Make circle
          const circleRadius = 15;
          const circleX = (initialPoint.x + finalPoint.x) / 2;
          const circleY = (initialPoint.y + finalPoint.y) / 2;
          const circlePosition = { x: circleX, y: circleY };
          const circle = new paper.Path.Circle(circlePosition, circleRadius);
          circle.strokeWidth = 0;
          circle.fillColor = '#aaa';
          // Make label
          const label = new paper.PointText();
          label.fillColor = 'white';
          label.content = this.getNextInspectionNumber();
          label.fontFamily = 'Siemens Roman';
          label.fontSize = 18;
          label.position = circlePosition;

          // Remove previous marker and create new one
          this.selectedItem.marker.remove();
          this.selectedItem.marker = new paper.Group(path, circle, label);
        } else if (this.lengthStage === 2) {
          const initialPoint =
            this.selectedItem.marker.children[0].segments[0].point;
          const finalPoint =
            this.selectedItem.marker.children[0].segments[3].point;

          // Make line and circle
          const circleRadius = 15;

          const points = this.calculateParallelPoints(
            initialPoint,
            finalPoint,
            event.point
          );

          const path = new paper.Path(
            initialPoint,
            points.middlePoint1,
            points.middlePoint2,
            finalPoint
          );

          // Convert middle points to Paper.js Point objects for easy calculations
          const p1 = new paper.Point(
            points.middlePoint1.x,
            points.middlePoint1.y
          );
          const p2 = new paper.Point(
            points.middlePoint2.x,
            points.middlePoint2.y
          );
          const mousePoint = event.point;

          // Calculate vectors
          const lineVector = p2.subtract(p1);
          const mouseVector = mousePoint.subtract(p1);

          // Project mouseVector onto lineVector
          let projection = lineVector
            .normalize()
            .multiply(mouseVector.dot(lineVector.normalize()));

          // Check if projection exceeds the line segment
          if (projection.length > lineVector.length) {
            projection = lineVector; // Snap to p2 if beyond
          } else if (projection.dot(lineVector) < 0) {
            projection = new paper.Point(0, 0); // Snap to p1 if before
          }

          // Calculate new circle position
          const circlePosition = p1.add(projection);
          const circle = new paper.Path.Circle(circlePosition, circleRadius);
          this.lengthMode = 0;
          path.strokeColor = '#aaa';
          path.strokeWidth = 4;
          circle.strokeWidth = 0;
          circle.fillColor = '#aaa';
          // Make label
          const label = new paper.PointText();
          label.fillColor = 'white';
          label.content = this.getNextInspectionNumber();
          label.fontFamily = 'Siemens Roman';
          label.fontSize = 18;
          label.position = circlePosition;

          // Remove previous marker and create new one
          this.selectedItem.marker.remove();
          this.selectedItem.marker = new paper.Group(path, circle, label);
        }
      };
    },
    initializeVisualTool() {
      this.visualTool = new paper.Tool();
      this.visualTool.onMouseDown = async (event) => {
        if (this.isTaskCompleted) {
          this.taskCompletedError();
          return;
        }

        if (this.isSelectingMarker(event)) {
          this.delegateToSelectTool(event);
        } else {
          if (this.processingNewBubble) {
            this.showProcessingBubblePopup();
            return;
          }
          this.selectedItem = {
            id: uuidv4(),
            type: 'visual',
            number: this.getNextInspectionNumber(),
            orientation: 'up',
            measurementCount: 1,
            measurements: [
              EMPTY_VISUAL_MEASUREMENT_VALUE,
              EMPTY_VISUAL_MEASUREMENT_VALUE,
              EMPTY_VISUAL_MEASUREMENT_VALUE,
              EMPTY_VISUAL_MEASUREMENT_VALUE,
            ],
            point: this.getImagePoint(event.point),
          };

          // Copy the data from the last marker of the same type
          const visualInspections = this.inspections.filter(
            (inspection) => inspection.type === 'visual'
          );
          if (visualInspections.length > 0) {
            const lastVisualInspection =
              visualInspections[visualInspections.length - 1];
            this.copyMarkerData(lastVisualInspection, this.selectedItem);
          }

          this.addNewInspection();
          this.makeSimpleMarker(this.selectedItem);

          const windowPoint = {
            x: event.event.clientX,
            y: event.event.clientY,
          };
          this.showInspectionDetail(windowPoint);
        }
      };
    },
    initializeGenericTool() {
      this.genericTool = new paper.Tool();
      this.genericTool.onMouseDown = async (event) => {
        if (this.isTaskCompleted) {
          this.taskCompletedError();
          return;
        }

        if (this.isSelectingMarker(event)) {
          this.delegateToSelectTool(event);
        } else {
          if (this.processingNewBubble) {
            this.showProcessingBubblePopup();
            return;
          }
          this.selectedItem = {
            id: uuidv4(),
            type: 'generic',
            number: this.getNextInspectionNumber(),
            unit: 'in',
            tolerance: '0.0000',
            decimalPlaces: 4,
            direction: '0',
            measurementCount: 1,
            measurements: [EMPTY_MEASUREMENT_VALUE],
            multipleMeasurements: false,
            point: this.getImagePoint(event.point),
          };

          // Copy the data from the last marker of the same type
          const genericInspections = this.inspections.filter(
            (inspection) => inspection.type === 'generic'
          );
          if (genericInspections.length > 0) {
            const lastGenericInspection =
              genericInspections[genericInspections.length - 1];
            this.copyMarkerData(lastGenericInspection, this.selectedItem);
          }

          this.addNewInspection();

          this.makeSimpleMarker(this.selectedItem);

          const windowPoint = {
            x: event.event.clientX,
            y: event.event.clientY,
          };
          this.showInspectionDetail(windowPoint);
        }
      };
    },

    initializeFreefindingTool() {
      this.freeFindingTool = new paper.Tool();
      this.freeFindingTool.onMouseDown = async (event) => {
        if (this.processingNewBubble) {
          this.$buefy.snackbar.open({
            duration: 5000,
            message: 'Hold on! The system is handling the last operation.',
            pauseOnHover: true,
            type: 'is-link',
            queue: false,
          });
          return;
        }

        if (this.isSelectingMarker(event)) {
          this.delegateToSelectTool(event);
        } else {
          if (this.processingNewBubble) {
            this.showProcessingBubblePopup();
            return;
          }
          this.selectedItem = {
            id: uuidv4(),
            type: 'freefinding',
            number: this.getNextInspectionNumber(),
            direction: '0',
            measurementCount: 1,
            measurements: [
              {
                value: '',
                images: [],
                comment: '',
              },
            ],
            multipleMeasurements: false,
            point: this.getImagePoint(event.point),
          };

          this.addNewInspection();

          this.makeSimpleMarker(this.selectedItem);

          const windowPoint = {
            x: event.event.clientX,
            y: event.event.clientY,
          };
          this.showInspectionDetail(windowPoint);
        }
      };
    },

    // TODO: This is a duplicate of the method in the RAT component should be a MIXIN
    // after we merge the new RAT tasks into the main branch
    createRATBlankRow() {
      return {
        id: uuidv4(),
        level: 1,
        assembly: '',
        itemNumber: '',
        partName: '',
        partNumber: '',
        quantity: 1,
        reuse: 0,
        repair: 0,
        replace: 0,
        missing: 0,
        clientReject: false,
        received: 0,
        reviewed: false,
        qualityControlOverride: false,
        visual: '',
        dim: '',
        ndt: '',
        summary: '',
        repairScope: '',
        estimatedLabor: '',
      };
    },

    async addRATRow(isMinor) {
      const newRow = this.createRATBlankRow();
      if (isMinor) newRow.isMinor = isMinor;
      const job = this.$store.state.JobsModule.currentJob;
      const result = await this.$store.dispatch('addRepairAssessmentRow', {
        job: job,
        rows: newRow,
        tool: this.tool,
      });

      return result.id;
    },

    initializeRATTool() {
      this.ratTool = new paper.Tool();
      this.ratTool.onMouseDown = async (event) => {
        if (this.isTaskCompleted) {
          this.taskCompletedError();
          return;
        }

        this.isItemDetailVisible = false;
        if (this.isSelectingMarker(event)) {
          this.delegateToSelectTool(event);
        } else {
          if (this.processingNewBubble) {
            this.showProcessingBubblePopup();
            return;
          }
          this.selectedItem = {
            type: 'rat',
            orientation: '0',
            point: this.getImagePoint(event.point),
          };

          const windowPoint = {
            x: event.event.clientX,
            y: event.event.clientY,
          };
          await this.addNewInspection();
          await this.showInspectionRAT(windowPoint);
          this.makeSimpleMarker(this.selectedItem);
        }
      };
    },
    initializeTextTool() {
      this.textTool = new paper.Tool();
      this.textTool.onMouseDown = async (event) => {
        if (this.isTaskCompleted) {
          this.taskCompletedError();
          return;
        }
        if (this.isSelectingMarker(event)) {
          this.delegateToSelectTool(event);
        } else {
          if (this.processingNewBubble) {
            this.showProcessingBubblePopup();
            return;
          }
          this.selectedItem = {
            id: uuidv4(),
            type: 'text',
            text: 'Example Text',
            textSize: 18,
            orientation: 'up',
            point: this.getImagePoint(event.point),
          };

          // Copy the data from the last marker of the same type
          const textAnnotations = this.inspections.filter(
            (inspection) => inspection.type === 'text'
          );
          if (textAnnotations.length > 0) {
            const lastTextAnnotation =
              textAnnotations[textAnnotations.length - 1];
            this.copyMarkerData(lastTextAnnotation, this.selectedItem);
          }

          this.annotations.push(this.selectedItem);
          this.makeTextMarker(this.selectedItem);

          this.addNewInspection();

          const windowPoint = {
            x: event.event.clientX,
            y: event.event.clientY,
          };
          this.showInspectionDetail(windowPoint);
        }
      };
    },
    initializeArrowTool() {
      this.arrowTool = new paper.Tool();
      this.arrowTool.onMouseDown = async (event) => {
        if (this.isTaskCompleted) {
          this.taskCompletedError();
          return;
        }
        if (this.arrowStage === 0) {
          if (this.isSelectingMarker(event)) {
            this.delegateToSelectTool(event);
          } else {
            if (this.processingNewBubble) {
              this.showProcessingBubblePopup();
              return;
            }
            this.selectedItem = {
              id: uuidv4(),
              type: 'arrow',
              point: this.getImagePoint(event.point),
            };
            this.annotations.push(this.selectedItem);
            // Copy the data from the last marker of the same type
            const arrowAnnotations = this.inspections.filter(
              (inspection) => inspection.type === 'arrow'
            );
            if (arrowAnnotations.length > 0) {
              const lastArrowAnnotation =
                arrowAnnotations[arrowAnnotations.length - 1];
              this.copyMarkerData(lastArrowAnnotation, this.selectedItem);
            }

            const path = new paper.Path(event.point, event.point);

            const marker = new paper.Group(path);

            this.selectedItem.marker = marker;
            this.arrowStage = 1;
          }
        } else {
          const startPoint =
            this.selectedItem.marker.children[0].segments[0].point;
          const endPoint = event.point;

          this.selectedItem.startPoint = this.getImagePoint(startPoint);
          this.selectedItem.point = this.getImagePoint(endPoint);

          this.makeArrowMarker(this.selectedItem);
          this.addNewInspection();
          this.arrowStage = 0;
        }
      };
      this.arrowTool.onMouseMove = (event) => {
        if (this.arrowStage === 1) {
          const initialPoint =
            this.selectedItem.marker.children[0].segments[0].point;

          this.selectedItem.marker.remove();
          const path = new paper.Path(initialPoint, event.point);
          path.strokeColor = '#aaa';
          path.strokeWidth = 4;
          this.selectedItem.marker = new paper.Group(path);
        }
      };
    },
    // start to initialize line
    initializeLineTool() {
      this.lineTool = new paper.Tool();
      this.lineTool.onMouseDown = async (event) => {
        if (this.isTaskCompleted) {
          this.taskCompletedError();
          return;
        }
        if (this.lineStage === 0) {
          if (this.isSelectingMarker(event)) {
            this.delegateToSelectTool(event);
          } else {
            if (this.processingNewBubble) {
              this.showProcessingBubblePopup();
              return;
            }
            // preparing instance of line after user selection of line
            this.selectedItem = {
              id: uuidv4(),
              type: 'line',
              point: this.getImagePoint(event.point),
            };
            this.annotations.push(this.selectedItem);

            // Copy the data from the last marker of the same type
            const lineAnnotations = this.inspections.filter(
              (inspection) => inspection.type === 'line'
            );
            if (lineAnnotations.length > 0) {
              const lastLineAnnotation =
                lineAnnotations[lineAnnotations.length - 1];
              this.copyMarkerData(lastLineAnnotation, this.selectedItem);
            }
            const path = new paper.Path(event.point, event.point);
            const marker = new paper.Group(path);

            this.selectedItem.marker = marker;
            this.lineStage = 1;
          }
        } else {
          const startPoint =
            this.selectedItem.marker.children[0].segments[0].point;
          const endPoint = event.point;

          this.selectedItem.startPoint = this.getImagePoint(startPoint);
          this.selectedItem.point = this.getImagePoint(endPoint);

          this.makeLineMarker(this.selectedItem);
          this.addNewInspection();
          this.lineStage = 0;
        }
      };
      this.lineTool.onMouseMove = (event) => {
        if (this.lineStage === 1) {
          const initialPoint =
            this.selectedItem.marker.children[0].segments[0].point;

          this.selectedItem.marker.remove();
          const path = new paper.Path(initialPoint, event.point);
          path.strokeColor = '#aaa';
          path.strokeWidth = 4;
          this.selectedItem.marker = new paper.Group(path);
        }
      };
    },
    remakeMarker(inspection, selected = false) {
      if (inspection.type === 'length') {
        this.makeLengthMarker(inspection, selected);
      } else {
        this.makeSimpleMarker(inspection, selected);
      }
    },
    makeSimpleMarker(inspection, markerSelected = false) {
      // Make the circle
      const circleRadius = this.circleRadiusBubble;
      const canvasPoint = this.getCanvasPoint(inspection.point);
      const circleX = canvasPoint.x;
      const circleY = canvasPoint.y - circleRadius * 2;
      const circleCenter = { x: circleX, y: circleY };
      const circle = new paper.Path.Circle(circleCenter, circleRadius);
      circle.strokeWidth = 0;

      // Make the triangle
      const circledy = canvasPoint.y - circleY;
      const circledx = canvasPoint.x - circleX;
      const circledist = Math.sqrt(circledx * circledx + circledy * circledy);

      const circlem = circledist / circleRadius;

      const intersection = new paper.Point(
        circleX + circledx / circlem,
        circleY + circledy / circlem
      );
      const theta = Math.PI / 3;
      const cosTheta = Math.cos(theta);
      const sinTheta = Math.sin(theta);

      const dx = intersection.x - 1 - circleCenter.x;
      const dy = intersection.y + 1 - circleCenter.y;

      const x1 = cosTheta * dx - sinTheta * dy + circleCenter.x;
      const y1 = sinTheta * dx + cosTheta * dy + circleCenter.y;

      const x2 = cosTheta * dx + sinTheta * dy + circleCenter.x;
      const y2 = -sinTheta * dx + cosTheta * dy + circleCenter.y;

      const secant1 = new paper.Point(x1, y1);
      const secant2 = new paper.Point(x2, y2);

      const triangle = new paper.Path(secant1, secant2, canvasPoint);
      triangle.closed = true;
      triangle.strokeWidth = 0;

      if (inspection.type === 'rat') {
        circle.fillColor = '#de9f00';
        triangle.fillColor = '#de9f00';
      } else {
        if (markerSelected) {
          circle.fillColor = '#15A1E1';
          triangle.fillColor = '#15A1E1';
        } else if (
          this.isCustomerReport ||
          this.hasNoMeasurements(inspection)
        ) {
          circle.fillColor = '#808080';
          triangle.fillColor = '#808080';
        } else if (
          this.isInInspectorMode &&
          this.hasAllMeasurements(inspection)
        ) {
          if (inspection.type === 'visual') {
            const notCompleted = inspection.measurements.every(
              (measurement) => measurement.value === 'notCompleted'
            );

            if (notCompleted) {
              circle.fillColor = '#808080';
              triangle.fillColor = '#808080';
            } else {
              if (this.areMeasurementsOk(inspection)) {
                circle.fillColor = '#66cc99';
                triangle.fillColor = '#66cc99';
              } else {
                circle.fillColor = '#f7B19a';
                triangle.fillColor = '#f7B19a';
              }
            }
          } else {
            if (this.areMeasurementsOk(inspection)) {
              circle.fillColor = '#66cc99';
              triangle.fillColor = '#66cc99';
            } else {
              circle.fillColor = '#f7B19a';
              triangle.fillColor = '#f7B19a';
            }
          }

          // TODO: check when type generic is ok or not
        } else if (inspection.type === 'generic') {
          if (this.areMeasurementsOk(inspection)) {
            circle.fillColor = '#66cc99';
            triangle.fillColor = '#66cc99';
          } else {
            circle.fillColor = '#f7B19a';
            triangle.fillColor = '#f7B19a';
          }

          // if (this.reportMode) {
          //   circle.fillColor = '#808080';
          //   triangle.fillColor = '#808080';
          // }
        } else if (inspection.type === 'freefinding') {
          circle.fillColor = '#66cc99';
          triangle.fillColor = '#66cc99';
        } else {
          circle.fillColor = markerSelected ? '#15A1E1' : '#808080';
          triangle.fillColor = markerSelected ? '#15A1E1' : '#808080';
        }
      }

      // Make the label
      const label = new paper.PointText();
      label.fillColor = 'white';

      if (inspection.type === 'rat') {
        label.content = 'RAT';
      } else {
        label.content = inspection.number;
      }

      label.fontFamily = 'Siemens Roman';
      label.fontSize = this.fontSizeBubble;
      label.position = circleCenter;

      const marker = new paper.Group(circle, triangle, label);
      if (inspection.orientation) {
        if (inspection.orientation === 'down') {
          label.rotation = 180;
          marker.rotate(180, canvasPoint);
        } else if (inspection.orientation === 'left') {
          label.rotation = 90;
          marker.rotate(270, canvasPoint);
        } else if (inspection.orientation === 'right') {
          label.rotation = 270;
          marker.rotate(90, canvasPoint);
        }
      }

      // If a marker already exists, remove it
      if (inspection.marker) {
        inspection.marker.remove();
      }

      inspection.marker = marker;
    },

    makeTextMarker(inspection) {
      const label = new paper.PointText();
      label.fillColor = 'black';
      label.content = inspection.text;
      label.fontFamily = 'Siemens Roman';
      label.fontSize = inspection.textSize;
      const canvasPoint = this.getCanvasPoint(inspection.point);
      label.position = canvasPoint;
      const marker = new paper.Group(label);

      if (inspection.orientation) {
        if (inspection.orientation === 'down') {
          marker.rotate(180, canvasPoint);
          console.log(marker.children[0].rotation);
        } else if (inspection.orientation === 'left') {
          marker.rotate(270, canvasPoint);
          console.log(marker.children[0].rotation);
        } else if (inspection.orientation === 'right') {
          marker.rotate(90, canvasPoint);
          console.log(marker.children[0].rotation);
        }
      }
      // If a marker already exists, remove it
      if (inspection.marker) {
        inspection.marker.remove();
      }
      inspection.marker = marker;
    },

    makeArrowMarker(inspection) {
      const canvasStartPoint = this.getCanvasPoint(inspection.startPoint);
      const canvasEndPoint = this.getCanvasPoint(inspection.point);

      // make sure the arrow tail is not too short by adding extra length
      // when initialPoint and finalPoint are too close
      if (
        Math.hypot(
          canvasEndPoint.x - canvasStartPoint.x,
          canvasEndPoint.y - canvasStartPoint.y
        ) < 15
      ) {
        // move start point farther apart from end point if the two are too close.
        if (canvasEndPoint.x >= canvasStartPoint.x) {
          canvasStartPoint.x -= 15;
        } else if (canvasEndPoint.y >= canvasStartPoint.y) {
          canvasStartPoint.y -= 15;
        } else if (canvasEndPoint.x < canvasStartPoint.x) {
          canvasStartPoint.x += 15;
        } else if (canvasEndPoint.y < canvasStartPoint.y) {
          canvasStartPoint.y += 15;
        }
      }

      // calculate arrowhead rotation angle
      const radian = Math.atan2(
        canvasEndPoint.y - canvasStartPoint.y,
        canvasEndPoint.x - canvasStartPoint.x
      );
      // arrowhead face downward
      let rotation;
      if (canvasEndPoint.y >= canvasStartPoint.y) {
        rotation = 90 + Math.abs((radian * 180) / Math.PI);
      } else {
        // arrowhead face upward
        rotation = 90 - Math.abs((radian * 180) / Math.PI);
      }

      const arrow = new paper.Path.RegularPolygon(
        new paper.Point(canvasEndPoint.x, canvasEndPoint.y + 2.5),
        3,
        10
      );
      arrow.strokeColor = '#aaa';
      arrow.fillColor = '#aaa';
      arrow.rotate(rotation);

      const path = new paper.Path(canvasStartPoint, canvasEndPoint);
      path.strokeColor = '#aaa';
      path.strokeWidth = 4;

      if (inspection.marker) {
        inspection.marker.remove();
      }
      inspection.marker = new paper.Group(path, arrow);
    },

    makeLineMarker(inspection) {
      const canvasStartPoint = this.getCanvasPoint(inspection.startPoint);
      const canvasEndPoint = this.getCanvasPoint(inspection.point);

      const path = new paper.Path(canvasStartPoint, canvasEndPoint);
      path.strokeColor = '#aaa';
      path.strokeWidth = 4;

      if (inspection.marker) {
        inspection.marker.remove();
      }
      inspection.marker = new paper.Group(path);
    },

    makeLengthMarker(inspection, markerSelected = false) {
      const firstPoint = this.getCanvasPoint(inspection.firstPoint);
      const secondPoint = this.getCanvasPoint(inspection.secondPoint);
      const thirdPoint = this.getCanvasPoint(inspection.thirdPoint);
      const fourthPoint = this.getCanvasPoint(inspection.fourthPoint);
      const circlePosition = this.getCanvasPoint(inspection.point);
      let color;
      if (this.isCustomerReport || this.hasNoMeasurements(inspection)) {
        color = '#808080';
      } else if (this.areMeasurementsOk(inspection)) {
        color = '#66cc99';
      } else {
        color = '#f7B19a';
      }

      if (markerSelected) {
        color = '#15A1E1';
      }

      // Make path
      const path = new paper.Path(
        firstPoint,
        secondPoint,
        thirdPoint,
        fourthPoint
      );
      path.strokeColor = '#aaa';
      path.strokeWidth = 4;

      // Make circle
      const circleRadius = this.circleRadiusBubble;
      const circle = new paper.Path.Circle(circlePosition, circleRadius);
      circle.strokeWidth = 0;
      circle.fillColor = color;

      // Make label
      const label = new paper.PointText();
      label.fillColor = 'white';
      label.content = inspection.number;
      label.fontFamily = 'Siemens Roman';
      label.fontSize = this.fontSizeBubble;
      label.position = circlePosition;

      // Remove previous marker and create new one
      if (inspection.marker) {
        inspection.marker.remove();
      }
      inspection.marker = new paper.Group(path, circle, label);
    },
    moveImageTo(oldPoint, point, finished) {
      const dx = oldPoint.x - point.x;
      const dy = oldPoint.y - point.y;
      const newImageX = this.image.position.x - dx;
      const newImageY = this.image.position.y - dy;

      this.image.position = new paper.Point({ x: newImageX, y: newImageY });
      this.inspections.forEach((inspection) => {
        if (
          inspection.marker &&
          !['text', 'arrow', 'line'].includes(inspection.type)
        ) {
          const newMarkerX = inspection.marker.position.x - dx;
          const newMarkerY = inspection.marker.position.y - dy;
          inspection.marker.position = new paper.Point({
            x: newMarkerX,
            y: newMarkerY,
          });
        }
      });

      this.annotations.forEach((annotation) => {
        if (annotation.marker) {
          const newMarkerX = annotation.marker.position.x - dx;
          const newMarkerY = annotation.marker.position.y - dy;
          annotation.marker.position = new paper.Point({
            x: newMarkerX,
            y: newMarkerY,
          });
        }
      });

      if (this.multipleMeasurementSelector) {
        const newMarkerX = this.multipleMeasurementSelector.position.x - dx;
        const newMarkerY = this.multipleMeasurementSelector.position.y - dy;
        this.multipleMeasurementSelector.position = new paper.Point({
          x: newMarkerX,
          y: newMarkerY,
        });
      }

      if (this.isItemDetailVisible && finished) {
        this.showInspectionDetail(
          this.getImagePoint(this.selectedItem.marker.position)
        );
      }
    },
    isMobile() {
      if (
        typeof window.orientation !== 'undefined' ||
        navigator.userAgent.indexOf('IEMobile') !== -1
      ) {
        return true;
      }
      return false;
    },
    focusOnMarker(inspection) {
      // Disabled temporarly the panning until we found out a better solution
      this.selectedItem = inspection;

      return;
      const { marker } = inspection;

      const { canvas } = this.$refs;
      const targetX = canvas.width / 2;
      const targetY = canvas.height / 2;
      const dx = targetX - marker.position.x;
      const dy = targetY - marker.position.y;
      const targetNumberOfFrames = 80;
      const dxStep = dx / targetNumberOfFrames;
      const dyStep = dy / targetNumberOfFrames;

      let frameNumber = 0;

      const animation = () => {
        if (frameNumber !== 0) {
          const lastFrameNumber = frameNumber - 1;
          const lastPoint = new paper.Point(
            lastFrameNumber * dxStep,
            lastFrameNumber * dyStep
          );
          const point = new paper.Point(
            frameNumber * dxStep,
            frameNumber * dyStep
          );
          const finishedAnimation = frameNumber === 1;
          this.moveImageTo(lastPoint, point, finishedAnimation);
        }

        if (frameNumber++ < targetNumberOfFrames) {
          requestAnimationFrame(animation);
        }
      };

      if (!this.isMobile()) {
        animation();
      }
      if (this.isInInspectorMode()) {
        this.remakeMarker(inspection, true);
      }
      this.selectedItem = inspection;
    },
    blurFromMarker(inspection) {
      this.remakeMarker(inspection);
      if (this.multipleMeasurementSelector) {
        this.multipleMeasurementSelector.remove();
      }
    },
    getImagePoint(canvasPoint) {
      if (this.image) {
        const scaledImageX = canvasPoint.x - this.image.bounds.x;
        const scaledImageY = canvasPoint.y - this.image.bounds.y;

        const unscaledImageX =
          scaledImageX / this.image.getMatrix().a + this.image.getMatrix().b;
        const unscaledImageY =
          scaledImageY / this.image.getMatrix().d + this.image.getMatrix().c;

        return { x: unscaledImageX, y: unscaledImageY };
      }
      return { x: canvasPoint.x, y: canvasPoint.y };
    },
    getCanvasPoint(imagePoint) {
      if (!imagePoint) {
        return { x: 0, y: 0 };
      }

      if (this.image) {
        const canvasX =
          (imagePoint.x + this.image.getMatrix().b) * this.image.getMatrix().a +
          this.image.bounds.topLeft.x;
        const canvasY =
          (imagePoint.y + this.image.getMatrix().c) * this.image.getMatrix().d +
          this.image.bounds.topLeft.y;

        return { x: canvasX, y: canvasY };
      }
      return { x: imagePoint.x, y: imagePoint.y };
    },
    showInspectionDetail(point) {
      if (this.isTaskCompleted) {
        return;
      }

      let querySelectorString = '.dij-diagrambuilder-inspection-detail';
      if (this.selectedItem.type === 'rat') {
        this.isItemDetailVisible = false;
        this.isItemRATVisible = true;
        querySelectorString = '.dij-diagrambuilder-inspection-rat';
      } else if (!this.isInInspectorMode()) {
        this.isItemRATVisible = false;
        this.isItemDetailVisible = true;
        querySelectorString = '.dij-diagrambuilder-inspection-detail';
      }

      this.$nextTick(() => {
        const modalElement = document.querySelector(querySelectorString);
        if (modalElement) {
          modalElement.style.left = `${point.x + 15}px`;
          modalElement.style.top = '100px';
        }
      });
    },

    async showInspectionRAT(point) {
      // await this.$store.dispatch(GET_JOB, this.$route.params.id);
      // const job = this.$store.state.JobsModule.currentJob;

      // const row = job.repairAssessment.find(
      //   (rat) => rat.id === this.selectedItem.ratRowID
      // );
      // const item = this.selectedItem;
      // this.selectedItem = { ...item, ...row };
      this.isItemRATVisible = true;
      this.$nextTick(() => {
        const modalElement = document.querySelector(
          '.dij-diagrambuilder-inspection-rat'
        );
        if (modalElement) {
          modalElement.style.left = `${point.x + 15}px`;
          modalElement.style.top = '100px';
        }
      });
    },

    showMultipleMeasurementSelector(inspection) {
      // By default select the first measurement
      if (typeof inspection.selectedMeasurementNumber === 'undefined') {
        inspection.selectedMeasurementNumber = 0;
      }

      // If the selector already exists, remove it from screen
      if (this.multipleMeasurementSelector) {
        this.multipleMeasurementSelector.remove();
      }

      if (inspection.type === 'visual') {
        return;
      }
      this.multipleMeasurementSelector = new paper.Group();

      if (inspection.type === 'runout' && !inspection.multipleMeasurements) {
        let boxSize = new paper.Size(50, 50);
        const tirBox = new paper.Path.Rectangle(new paper.Point(0, 0), boxSize);
        tirBox.strokeWidth = 0;
        if (inspection.selectedMeasurementNumber === 0) {
          tirBox.fillColor = 'lightblue';
        } else if (this.hasMeasurement(inspection, 0)) {
          if (this.isMeasurementOk(inspection, 0)) {
            tirBox.fillColor = '#66cc99';
          } else {
            if (inspection.measurements[0].value) {
              tirBox.fillColor = '#f7B19a';
            } else {
              tirBox.fillColor = '#aaa';
            }
          }
        } else {
          tirBox.fillColor = '#aaa';
        }

        const tirLabel = new paper.PointText();
        tirLabel.content = 'TIR';
        tirLabel.fillColor = 'white';
        tirLabel.fontFamily = 'Siemens Roman';
        tirLabel.fontSize = 18;
        tirLabel.position = new paper.Point(
          boxSize.width / 2,
          boxSize.height / 2
        );

        const tirButton = new paper.Group(tirBox, tirLabel);
        tirButton.position = new paper.Point(0, 0);

        this.multipleMeasurementSelector.addChild(tirButton);

        boxSize = new paper.Size(100, 50);
        const positionBox = new paper.Path.Rectangle(
          new paper.Point(0, 0),
          boxSize
        );
        positionBox.strokeWidth = 0;
        if (inspection.selectedMeasurementNumber === 1) {
          positionBox.fillColor = 'lightblue';
        } else if (this.hasMeasurement(inspection, 0)) {
          if (this.isMeasurementOk(inspection, 0)) {
            positionBox.fillColor = '#66cc99';
          } else {
            if (inspection.measurements[0].value) {
              positionBox.fillColor = '#f7B19a';
            } else {
              positionBox.fillColor = '#aaa';
            }
          }
        } else {
          positionBox.fillColor = '#aaa';
        }

        const positionLabel = new paper.PointText();
        positionLabel.content = 'Position';
        positionLabel.fillColor = 'white';
        positionLabel.fontFamily = 'Siemens Roman';
        positionLabel.fontSize = 18;
        positionLabel.position = new paper.Point(
          boxSize.width / 2,
          boxSize.height / 2
        );

        const positionButton = new paper.Group(positionBox, positionLabel);
        positionButton.position = new paper.Point(75, 0);

        this.multipleMeasurementSelector.addChild(positionButton);
      } else {
        // Build the multiple points selection box
        for (let i = 0; i < inspection.measurementCount; i++) {
          const boxSize = new paper.Size(50, 50);
          const box = new paper.Path.Rectangle(new paper.Point(0, 0), boxSize);
          box.strokeWidth = 0;
          if (inspection.selectedMeasurementNumber === i) {
            box.fillColor = 'lightblue';
          } else if (this.hasMeasurement(inspection, i)) {
            if (this.isMeasurementOk(inspection, i)) {
              box.fillColor = '#66cc99';
            } else {
              if (inspection.measurements[i].value) {
                box.fillColor = '#f7B19a';
              } else {
                box.fillColor = '#aaa';
              }
            }
          } else {
            box.fillColor = '#aaa';
          }

          const label = new paper.PointText();
          if (inspection.type === 'runout') {
            if (inspection.multipleMeasurements) {
              label.content = i * (360 / inspection.measurementCount);
              label.content = Math.round(label.content);
            } else {
              if (i === 0) {
                label.content = 'TIR';
              } else {
                label.content = 'Pos';
              }
            }
          } else {
            label.content = i + 1;
          }
          label.fillColor = 'white';
          label.fontFamily = 'Siemens Roman';
          label.fontSize = 18;
          label.position = new paper.Point(
            boxSize.width / 2,
            boxSize.height / 2
          );

          const button = new paper.Group(box, label);
          button.position = new paper.Point(i * 50, 0);

          this.multipleMeasurementSelector.addChild(button);
        }
      }

      const point = this.getCanvasPoint(inspection.point);
      const xPos =
        inspection.measurementCount > 1 ? inspection.measurementCount : 0;
      this.multipleMeasurementSelector.position = new paper.Point(
        point.x - xPos / 2,
        point.y - 80
      );
    },
    isInInspectorMode() {
      if (this.selectedItem && this.selectedItem.type === 'rat') return false;
      return this.tool === 'inspector';
    },
    hasNoMeasurements(inspection) {
      if (inspection.type === 'visual') {
        return !inspection.measurements.length > 0;
      }

      if (inspection.type === 'generic') {
        return (
          (!inspection.images || inspection.images.length === 0) &&
          inspection.measurements &&
          inspection.measurements.length !== 0 &&
          inspection.measurements[0].value === ''
        );
      }

      if (!inspection.measurements || inspection.measurements.length === 0) {
        return true;
      }

      for (let i = 0; i < inspection.measurements.length; i++) {
        const measurement = inspection.measurements[i];
        if (
          measurement &&
          measurement.value !== undefined &&
          measurement.value !== null &&
          !isNaN(measurement.value) &&
          measurement.value !== ''
        ) {
          return false;
        }
      }

      return true;
    },
    hasAllMeasurements(inspection) {
      if (inspection.type === 'visual') {
        return inspection.measurements.length > 0;
      }

      if (inspection.type === 'generic') {
        return inspection.images && inspection.images.length > 0;
      }

      if (!inspection.measurements || inspection.measurements.length === 0) {
        return false;
      }
      for (let i = 0; i < inspection.measurements.length; i++) {
        const measurement = inspection.measurements[i];
        if (
          !measurement ||
          measurement.value === undefined ||
          measurement.value === null ||
          isNaN(measurement.value) ||
          measurement.value === ''
        ) {
          return false;
        }
      }
      return true;
    },
    hasMeasurement(inspection, measurementNumber) {
      const measurement = inspection.measurements[measurementNumber]?.value;
      if (measurement !== undefined && measurement !== null) {
        return true;
      }

      return false;
    },
    areMeasurementsOk(inspection) {
      let ok = true;
      if (inspection.type === 'generic') {
        ok =
          (inspection.images && inspection.images.length > 0) ||
          (inspection.comment && inspection.comment.length) > 0 ||
          (inspection.measurements[0] &&
            inspection.measurements[0].value !== '');
      } else if (inspection.type === 'visual') {
        let notFilled = inspection.measurements.every(
          (measurement) => measurement.value === ''
        );

        ok = notFilled;
      } else {
        const limits = this.getLimits(inspection);
        const lowerLimit = limits[0];
        const upperLimit = limits[1];

        for (let i = 0; i < inspection.measurements.length; i++) {
          const measurement = parseFloat(inspection.measurements[i]?.value);
          ok = measurement >= lowerLimit && measurement <= upperLimit;
          if (!ok) {
            break;
          }
        }

        if (inspection.type === 'runout' && !inspection.multipleMeasurements) {
          const measurement = parseFloat(inspection.measurements[0]?.value);
          ok = measurement >= lowerLimit && measurement <= upperLimit;
        }
      }
      return ok;
    },
    isMeasurementOk(inspection, measurementNumber) {
      let ok = true;

      if (inspection.type === 'generic') {
        ok =
          (inspection.images && inspection.images.length > 0) ||
          (inspection.comment && inspection.comment.length) > 0;
      } else if (inspection.type === 'visual') {
        ok = inspection.measurements.length > 0;
      } else {
        const limits = this.getLimits(inspection);
        const lowerLimit = limits[0];
        const upperLimit = limits[1];

        const measurement = parseFloat(
          inspection.measurements[measurementNumber]?.value
        );
        if (measurement) {
          ok = measurement >= lowerLimit && measurement <= upperLimit;
        } else {
          ok = false;
        }
      }

      return ok;
    },
    getLimits(inspection) {
      let lowerLimit;
      let upperLimit;
      if (inspection.type === 'runout') {
        upperLimit = Math.abs(inspection.tolerance);
        lowerLimit = -upperLimit;
      } else {
        const floatExpectedValue = parseFloat(inspection.expectedValue);

        const floatNegativeTolerance =
          parseFloat(inspection.negativeTolerance) || 0;
        const floatPositiveTolerance =
          parseFloat(inspection.positiveTolerance) || 0;
        switch (inspection.toleranceType) {
          case 'normal':
            lowerLimit = floatExpectedValue - floatNegativeTolerance;
            upperLimit = floatExpectedValue + floatPositiveTolerance;

            break;
          case 'positive':
            upperLimit = Math.max(
              floatExpectedValue + floatNegativeTolerance,
              floatExpectedValue + floatPositiveTolerance
            );
            lowerLimit = Number.MIN_VALUE;
            break;
          case 'negative':
            lowerLimit = Math.min(
              floatExpectedValue + floatNegativeTolerance,
              floatExpectedValue + floatPositiveTolerance
            );
            upperLimit = Number.MAX_VALUE;
            break;
          default:
            throw new Error(
              `Failed to parse toleranceType for inspection with id ${inspection.id} and type ${inspection.type}: toleranceType ${inspection.toleranceType}`
            );
        }
      }

      let decimalPlaces = this.countDecimalPlaces(inspection.expectedValue);
      if (inspection.type === 'runout') {
        decimalPlaces = this.countDecimalPlaces(inspection.tolerance);
      }

      return [
        parseFloat(lowerLimit).toFixed(decimalPlaces),
        parseFloat(upperLimit).toFixed(decimalPlaces),
      ];
    },
    countDecimalPlaces(value) {
      if (!value) {
        return 0;
      }

      let numString = typeof value === 'number' ? value.toString() : value;

      let parts = numString.split('.');

      if (parts.length === 2) {
        return parts[1].length;
      } else {
        return 0;
      }
    },
    isOutsideMoveDeadzone(initialPoint, currentPoint) {
      return (
        Math.abs(initialPoint.x - currentPoint.x) > 10 ||
        Math.abs(initialPoint.y - currentPoint.y) > 10
      );
    },
    isNewTask() {
      return !this.task.diagram && !this.task.instruction;
    },
    isSelectingMarker(event) {
      const hitResult = this.scope.project.hitTest(event.point);
      return hitResult && hitResult.type !== 'pixel';
    },
    async handleOnMouseDownMarkerSelection(event) {
      const hitResult = this.scope.project.hitTest(event.point);
      const item = hitResult.item.parent
        ? hitResult.item.parent
        : hitResult.item;

      // If the user clicks the measurementSelector, update the point
      if (item.parent === this.multipleMeasurementSelector) {
        this.selectedItem.selectedMeasurementNumber =
          item.parent.children.indexOf(item);
      } else {
        // Find the selected item
        const allItems = this.inspections.concat(this.annotations);
        const foundItem = allItems.filter(
          (inspection) => inspection.marker === item
        )[0];

        if (foundItem) {
          this.isItemDetailVisible = false;
          if (foundItem.type === 'rat') {
            // await this.$store.dispatch(GET_JOB, this.$route.params.id);
            const job = this.$store.state.JobsModule.currentJob;

            let row = {};

            if (job.repairAssessment)
              row = job.repairAssessment.find(
                (rat) => rat.id === foundItem.ratRowID
              );

            // Assigning the RAT row into the selected item without braking the reactivity
            for (let key in row) {
              if (row.hasOwnProperty(key)) {
                foundItem[key] = row[key];
              }
            }

            this.selectedItem = foundItem;
          } else {
            this.isItemRATVisible = false;
            this.selectedItem = foundItem;
          }
          this.isMouseButtonDown = true;
        }
      }
    },
    delegateToSelectTool(event) {
      // Handle the onMouseDown marker selection and then enable the selectTool
      // to handle both onMouseDrag(if any) and onMouseUp
      this.handleOnMouseDownMarkerSelection(event);
      this.switchBackTool = this.scope.tool;
      this.selectTool.activate();
    },
    copyMarkerData(from, to) {
      switch (from.type) {
        case 'diameter':
        case 'length':
          to.unit = from.unit;
          to.expectedValue = from.expectedValue;
          to.toleranceType = from.toleranceType;
          to.positiveTolerance = from.positiveTolerance;
          to.negativeTolerance = from.negativeTolerance;
          to.orientation = from.orientation;
          to.measurementCount = from.measurementCount;
          to.multipleMeasurements = from.multipleMeasurements;
          to.measurements = [];
          for (let i = 0; i < to.measurementCount; i++) {
            to.measurements.push(EMPTY_MEASUREMENT_VALUE);
          }
          break;
        case 'runout':
          to.unit = from.unit;
          to.tolerance = from.tolerance;
          to.direction = from.direction;
          to.orientation = from.orientation;
          to.measurementCount = from.measurementCount;
          to.multipleMeasurements = from.multipleMeasurements;
          to.measurements = [];
          for (let i = 0; i < to.measurementCount; i++) {
            to.measurements.push(EMPTY_MEASUREMENT_VALUE);
          }
          break;
        case 'visual':
          to.orientation = from.orientation;
          to.measurementCount = 1;
          to.measurements = [
            EMPTY_VISUAL_MEASUREMENT_VALUE,
            EMPTY_VISUAL_MEASUREMENT_VALUE,
            EMPTY_VISUAL_MEASUREMENT_VALUE,
            EMPTY_VISUAL_MEASUREMENT_VALUE,
          ];
          break;
        case 'generic':
          to.measurementName = from.measurementName;
          to.orientation = from.orientation;
          to.measurementCount = 1;
          to.measurements = [EMPTY_MEASUREMENT_VALUE];
          to.multipleMeasurements = from.multipleMeasurements;
          break;
        case 'arrow':
          break;
        case 'line':
          break;
        case 'text':
          to.text = from.text;
          to.textSize = from.textSize;
          to.orientation = from.orientation;
          break;
        default:
          throw new Error(`Unknown Type: ${to.type}`);
      }
    },
    beforeSave() {
      this.task.subtype = 'diagram';
      this.inspections.forEach((inspection) => {
        delete inspection.marker;
        delete inspection.selectedMeasurementNumber;
      });

      this.annotations.forEach((inspection) => delete inspection.marker);

      this.task.diagram = {
        src: this.image.image.src,
        inspections: this.inspections,
        annotations: this.annotations,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.dij-diagrambuilder {
  position: relative;
  height: 100%;
}

.dij-diagrambuilder-inspection-rat {
  position: fixed;
  border: 1px solid lightgray;
  z-index: 1;

  .modal-card-head,
  .modal-card-foot {
    display: flex;
    justify-content: space-between;
    padding: 5px;
  }
}

.dij-diagrambuilder-toolbar-rat {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: #656565;
  &.disabled {
    opacity: 0.5;
    cursor: unset;
  }

  &.dij-tool-active {
    color: #3fa9f5;
  }
}

.dij-diagrambuilder-toolbar-freefinding {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: #656565;
  &.disabled {
    opacity: 0.5;
    cursor: unset;
  }

  &.dij-tool-active {
    color: #3fa9f5;
  }
}

.dij-diagrambuilder-canvas-container {
  position: relative;
  height: 100%;
  // lower the text size for label and fields
  .field ::v-deep label,
  .control ::v-deep input,
  ::v-deep .control input {
    font-size: 75%;
  }
}
.dij-diagrambuilder-zoomoption {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 10px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 40%);
  color: dimgray;
  padding: 10px;
  z-index: 1;

  span {
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: unset;
    }
  }
}

.dij-diagrambuilder-canvas {
  position: absolute;
  height: 100%;
  canvas {
    // position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
  }
}
.dij-diagrambuilder-upload {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    cursor: pointer;
  }
}
.dij-diagrambuilder-inspection-detail {
  position: fixed;
  border: 1px solid lightgray;
  z-index: 1;

  .modal-card-head,
  .modal-card-foot {
    display: flex;
    justify-content: space-between;
    padding: 5px;
  }
}
.dij-diagrambuild-job-info {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 20px;
  font-size: 12px;
}

.bubble-zoom {
  // Needs to be calculated taking into accout the inspections
  right: 225px;
}

.bubble-zoom-at-margin {
  // Needs to be calculated taking into accout the inspections
  right: 0;
}
</style>
