export default {
  SET_CURRENT_TASK: (state, task) => {
    state.currentTask = task;
  },

  SET_CURRENT_TASK_DIAGRAM_SOURCE: (state, imageSource) => {
    state.currentTask.diagram = state.currentTask.diagram || {};
    state.currentTask.diagram.src = imageSource;
  },

  UNLOAD_CURRENT_TASK: (state) => {
    state.currentTask = null;
  },

  SET_CURRENT_FOLDER: (state, folder) => {
    state.currentFolder = folder;
  },
};
