import client from '../../../config/api';
import QuestionDTO from '../../../shared/dtos/QuestionDTO';
import SET_LOADING_STATE from '../../loading/mutations/mutationTypes';
import {
  LOCAL_SET_QUESTIONS,
  LOCAL_REMOVE_QUESTION,
  LOCAL_UPDATE_QUESTION,
} from '../mutations/mutationTypes';

export default {
  async SAVE_QUESTIONS(_1, payload) {
    const { taskId, data } = payload;
    return client.post(`/questions/${taskId}`, data);
  },
  async GET_QUESTIONS({ commit }, taskId) {
    const result = await client.get(`/questions/${taskId}`);
    commit(LOCAL_SET_QUESTIONS, result.data);
  },
  async DELETE_QUESTION({ commit }, questionId) {
    if (!questionId) {
      return;
    }

    await client.delete(`/question/${questionId}`);
    commit(LOCAL_REMOVE_QUESTION, questionId);
  },
  async CREATE_QUESTION({ commit }, data) {
    const { taskId, question } = data;
    if (!taskId || !question) {
      console.log('Missing requred params for request');
      return;
    }
    const questionDto = new QuestionDTO(question);
    commit(SET_LOADING_STATE, { value: true }, { root: true });
    const response = await client.post(`/question/${taskId}`, questionDto);
    commit(SET_LOADING_STATE, { value: false }, { root: true });
    return response.data;
  },
  async CREATE_QUESTIONS({ commit }, data) {
    console.log('CREATE_QUESTIONS', data);
    const { taskId, questions } = data;
    if (!taskId || questions.length === 0) {
      console.log('Missing requred params for request');
      return;
    }

    const questionsDTO = questions.map((question) => new QuestionDTO(question));

    commit(SET_LOADING_STATE, { value: true }, { root: true });
    const response = await client.post(`/questions/${taskId}`, questionsDTO);
    commit(SET_LOADING_STATE, { value: false }, { root: true });
    return response.data;
  },
  async UPDATE_QUESTION({ commit }, data) {
    const { _id } = data;
    if (!_id) {
      console.log('Missing requred params for request _id');
      return;
    }
    const questionDto = new QuestionDTO(data);
    // commit(SET_LOADING_STATE, { value: true }, { root: true });
    await client.put(`/question/${_id}`, {
      ...questionDto,
      isInspector: data.isInspector,
    });
    commit(SET_LOADING_STATE, { value: false }, { root: true });
    commit(LOCAL_UPDATE_QUESTION, questionDto);
  },
};
