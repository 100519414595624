import {
  PublicClientApplication,
  InteractionRequiredAuthError,
  CacheLookupPolicy,
} from '@azure/msal-browser';

const msal = new PublicClientApplication({
  auth: {
    clientId: process.env.VUE_APP_AZUREAD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AZUREAD_TENANT_ID}`,
    redirectUri: process.env.VUE_APP_AZUREAD_REDIRECT_URI,
  },
});

const initializePromise = msal.initialize();

async function loginRedirect() {
  await initializePromise;
  await msal.loginRedirect({
    scopes: [`${process.env.VUE_APP_AZUREAD_CLIENT_ID}/.default`],
  });
}

async function login() {
  await initializePromise;
  const account = msal.getAllAccounts()[0];
  try {
    await msal.ssoSilent({
      account,
      scopes: [`${process.env.VUE_APP_AZUREAD_CLIENT_ID}/.default`],
    });
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError) {
      await loginRedirect();
    }
  }
}

async function logoutRedirect() {
  await initializePromise;
  return msal.logoutRedirect();
}

async function logout() {
  await initializePromise;
  await logoutRedirect();
}

async function handleRedirect() {
  await initializePromise;
  await msal.handleRedirectPromise();
}

async function acquireToken(forceRefresh) {
  await initializePromise;

  try {
    const account = msal.getAllAccounts()[0];

    const tokenOptions = {
      account,
      scopes: [`${process.env.VUE_APP_AZUREAD_CLIENT_ID}/.default`],
    };

    // Force the creation of a new access token
    if (forceRefresh) {
      tokenOptions.cacheLookupPolicy = CacheLookupPolicy.RefreshTokenAndNetwork;
    }

    // Try to acquire the token silently
    const silentResult = await msal.acquireTokenSilent(tokenOptions);

    sessionStorage.setItem('accessToken', silentResult.accessToken);
    return silentResult.accessToken;
  } catch (err) {
    // If silent token acquisition fails, fall back to interactive method
    if (err instanceof InteractionRequiredAuthError) {
      const tokenOptions = {
        scopes: [`${process.env.VUE_APP_AZUREAD_CLIENT_ID}/.default`],
      };

      // Force the creation of a new access token
      if (forceRefresh) {
        tokenOptions.cacheLookupPolicy =
          CacheLookupPolicy.RefreshTokenAndNetwork;
      }

      const interactiveResult = await msal.acquireTokenRedirect(tokenOptions);

      if (interactiveResult) {
        sessionStorage.setItem('accessToken', interactiveResult.accessToken);
        return interactiveResult.accessToken;
      }

      throw new Error('Failed to obtain access token');
    }

    throw err;
  }
}

export { login, logout, handleRedirect, acquireToken };
