<template>
  <b-modal
    :active="isActive"
    :can-cancel="['escape', 'x', 'outside']"
    @close="onClose"
    custom-class="modal-wrapper"
    :full-screen="fullScreenModal"
  >
    <component
      v-if="currentComponent"
      :is="currentComponentType"
      :isEditMode="isEditMode"
      :instruction="instruction"
      @itemSelected="handleItemSelection"
      @onDelete="onDelete"
      @onCancel="onCancel"
      @onSave="onSave"
      @onUpdate="onUpdate"
    ></component>
    <template v-else>
      <p>Loading...</p>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ItemSelectionDialog',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    instruction: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currentComponentType: 'selection',
      // Object to store dynamically imported components
      lazyComponents: {},
      fullScreenModal: false,
    };
  },
  watch: {
    isActive(newVal) {
      if (newVal) {
        this.currentComponentType = this.instruction?.type || 'selection';
        this.fullScreenModal =
          this.instruction?.type === 'tablequestion' ||
          this.instruction?.type === 'importcsv';
        this.loadComponent(this.currentComponentType); // Load component when isActive changes
      }
    },
  },
  computed: {
    isEditMode() {
      return this.instruction !== null;
    },
    type() {
      return this.instruction?.type || 'selection';
    },
    currentComponent() {
      return (
        this.lazyComponents[this.currentComponentType.toLowerCase()] || null
      );
    },
  },
  methods: {
    onDelete() {
      this.$emit('onDeleteItem', this.instruction);
    },
    onCancel() {
      this.currentComponentType = 'selection';
      if (this.fullScreenModal) {
        this.fullScreenModal = false;
      }
      this.onClose();
    },
    onSave(itemData) {
      this.$emit('onNewItem', itemData);
    },
    onUpdate(itemData) {
      this.$emit('onUpdateItem', itemData);
    },
    handleItemSelection(selectedItem) {
      this.currentComponentType = selectedItem.type;
      this.fullScreenModal = selectedItem.type === 'tablequestion';
      this.loadComponent(selectedItem.type); // Load the selected component
      if (selectedItem.type === 'tablequestion') {
        this.fullScreenModal = true;
      }
    },
    onClose() {
      this.$emit('onClose');
    },
    async loadComponent(type) {
      if (!this.lazyComponents[type.toLowerCase()]) {
        const component = await import(`./types/${type}`);
        this.$set(this.lazyComponents, type.toLowerCase(), component.default);
        this.$options.components[type] = component.default;
      }
    },
  },
};
</script>

<style scoped>
.modal-content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  background: white;
  border-radius: 10px;
  padding: 38px;
  margin: 0 25%;
}
</style>
