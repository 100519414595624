const de = {
  task_page: {
    create_new_task: 'Neue Aufgabe erstellen',
    back_to_product_list:
      'Nachdem Sie fertig sind, gehen Sie zurück zur Produktvorlagenliste, damit andere an dieser Vorlage arbeiten können',
    back_to_work_order_list:
      'Nachdem Sie fertig sind, gehen Sie zurück zur Arbeitsauftragliste, damit andere an dieser Arbeitsauftrag können',
  },

  create_new_user_page: {
    create_new_user: 'Neuen Benutzer erstellen',
    create_user: 'Benutzer erstellen',
    firstName: 'Vorname',
    lastName: 'Nachname',
    gid: 'GID',
    email: 'E-Mail',
    password: 'Passwort',
    role: 'Rolle',
    all_fields_required: 'Bitte füllen Sie alle erforderlichen Felder aus.',
    user_failed: 'Benutzererstellung fehlgeschlagen.',
    user_success: 'Benutzer wurde erfolgreich erstellt.',
    password_validation: 'Passwortanforderungen: mindestens 8 Zeichen, ein Kleinbuchstabe, ein Großbuchstabe, eine Zahl, keine Teile Ihres Benutzernamens.'
  },

  change_password_page: {
    change_password: 'Passwort ändern',
    change_user_password: 'Benutzerpasswort ändern',
    password_change_success: 'Passwort wurde erfolgreich geändert',
    secret_question_success: 'Geheimfrage wurde erfolgreich gestellt',
    password_change_fail: 'Das Passwort konnte nicht geändert werden.',
    secret_question_fail: 'Geheimfrage konnte nicht festgelegt werden',
    email: 'E-Mail',
    oldPassword: 'Altes Passwort',
    password: 'Neues Passwort',
    confirmNewPassword: 'Neues Passwort bestätigen',
    password_should_match: 'Das neue Passwort und das bestätigte Passwort müssen übereinstimmen.',
    password_validation: 'Passwortanforderungen: mindestens 8 Zeichen, ein Kleinbuchstabe, ein Großbuchstabe, eine Zahl, keine Teile Ihrer E-Mail.',
    passwordLowerCase: "Das Passwort muss mindestens einen Kleinbuchstaben enthalten.",
    passwordUpperCase: "Das Passwort muss mindestens einen Großbuchstaben enthalten.",
    passwordNumber: "Das Passwort muss mindestens eine Zahl enthalten.",
    passwordMinLength: "Das Passwort muss mindestens 8 Zeichen lang sein.",
    passwordNoUsernameParts: "Das Passwort darf keine Teile des Benutzernamens enthalten."
  },
  
  profile_page: {
    myProfile: 'Mein Profil',
    account: 'Konto',
    security: 'Sicherheit',
    preferences: 'Einstellungen',
    profileUpdated: 'Profil erfolgreich aktualisiert',
    updatePreferences: 'Einstellungen aktualisieren',
    updateAccount: 'Konto aktualisieren'
  },

  task_view: {
    cancel: 'Abbrechen',
    save: 'Speichern',
    delete: 'Löschen',
    deleteConfirmation: 'Sind Sie sicher, dass Sie die Aufgabe <b>{taskTitle}</b> <b>löschen</b> möchten?',
    deleteTask: 'Aufgabe löschen',
  },

  product_template_list: 'Produktvorlagenliste',
  work_order_list: 'Arbeitsauftragsliste',
  update: 'Aktualisieren',
  order: 'Auftrag',
  select_folder: 'Ordner auswählen',
  move: 'Verschieben',
  add_task: 'Aufgabe hinzufügen',
  add_folder: 'Ordner hinzufügen',
  add_field: 'Feld hinzufügen',
  message_order_drag: 'Jetzt können Sie Ihre Aufgaben, mittels ziehen, in die gewünschte Reihenfolge bringen',
  move_folder: 'In den Ordner verschieben',
  change_log: 'Änderungsprotokoll',

  admin: 'Administrator',
  editor: 'Editor',
  publisher: 'Veröffentlichen',
  inspector: 'Inspektor',
  report: 'Bericht',
  dashboard: 'Instrumententafel',
  language: 'Sprache',
  profile: 'Profil',
  view_profile: 'Profil anzeigen',
  manage_fleet_data: 'Flottendaten verwalten',
  report_issue: 'Melde ein Problem',
  request_content: 'Inhalt anfordern',
  contact_us: 'Kontaktiere Uns',
  training: 'Schulung',
  log_out: 'Ausloggen',

  choose_language: 'Wählen Sie Ihre bevorzugte Sprache.',

  create_new_product: 'Neues Produkt erstellen',
  title: 'Titel',
  product_line: 'Produktlinie',
  original_OEM: 'Ursprünglicher Hersteller',
  frame_type: 'Maschinentyp',
  model: 'Modell',
  part_number: 'Teilenummer',
  document_number: 'Dokumentnummer',
  revision: 'Revision',
  cancel: 'Abbrechen',
  submit: 'Einreichen',
  delete: 'Löschen',
  save: 'Speichern',
  done: 'Erledigt',
  new_product_success_message: 'Neues Produkt erfolgreich erstellt',
  new_product_fail_message: 'Fehler beim Erstellen eines neuen Jobs! Bitte versuche es erneut.',


  // Tool names inside diagram
  'Selection Tool': 'Auswahlwerkzeug',
  'Pan Viewport': 'Übersichtsfenster',
  'Diameter': 'Durchmesser',
  'Run-Out': 'Rundlaufmessung',
  'Linear Length': 'Lineare Länge',
  'Visual Inspection': 'Visuelle Inspektion',
  'Text': 'Text',
  'Generic Measurement': 'Generische Messung',
  'Arrow': 'Pfeil',
  'Line': 'Linie',
  'Replace Image': 'Bild ersetzen',

  // Bubble names
  generic: 'Generische Marker',
  runout: 'Rundlaufmessung',
  length: 'Länge',
  diameter: 'Durchmesser',
  arrow: 'Pfeil',
  line: 'Linie',
  text: 'Text',
  visualInspection: 'Visuelle Inspektion',

  //Bubble properties
  label_numbers_only: 'Label (nur Nummer)',
  expected_value: 'Erwarteter Wert',
  decimals: 'Dezimalstellen',
  units: 'Einheiten',
  inch: 'Zoll',
  mm: 'mm',
  tolerance_type: 'Art der Toleranz',
  tolerance: 'Toleranz',
  multiple_positions: 'Mehrere Positionen',
  orientation: 'Orientierung',
  rotate_text: 'Text drehen',
  measurement_name: 'Benennung der Messung',
  visual_name: 'Benennung des Visuals',
  marker_description: 'Beschreibung',
  direction: 'Richtung',
  degrees: 'Grad',
  clock: 'Uhr',

  //Save or discard dialog
  save_or_discard_dialog_content: 'Möchtest du deine Änderungen speichern',
  discard: 'Verwefen',
  stay: 'Bleibe',
  back: 'Zurück',
  dismiss: 'Entlassen',

  import_drawing: 'Zeichnung importieren',
  work_order_list: 'Arbeitsauftragsliste',


  edit_name: 'Namen Bearbeiten',
  reopen: 'Wieder Aufnehmen',

  //Instruction actions
  create_new_item: 'Neues Element erstellen',
  choose_one_item: 'Ein Element wählen',
  order_item: 'Artikel sortieren',
  action: 'Aktion',
  yes_no: 'Ja/Nein',
  options: 'Optionen',
  text: 'Text',
  table: 'Tabelle',
  import_csv: 'CSV Importieren',

  action_requested: 'Handlung angefordert',
  picture_taken_is_mandatory: 'Die Bildaufnahme ist verpflichtend.',
  picture_required: 'Bild erforderlich.',
  your_question: 'Ihre Frage',
  question: 'Frage',
  option: 'Möglichkeit',
  add_an_option: 'Fügen Sie eine Option hinzu',
  multiple_choice: 'Mehrfachauswahl',
  text_or_number: 'Text oder Nummer',

  number_of_rows: 'Anzahl der Zeilen',
  number_of_columns: 'Anzahl der Spalten',
  edit_rows: 'Zeilen bearbeiten',
  edit_columns: 'Spalten bearbeiten',
  only_numbers_and_alphabets: 'Tabellenüberschriften dürfen nicht leer sein',
  table_headings_not_empty: 'Innerhalb einer Zelle sind nur Zahlen und Alphabete mit bis zu 16 Zeichen zulässig',
  upload_diagram: 'Diagramm hochladen',

  yes: 'Ja',
  no: 'Nein',




  product_template_list: 'Produktvorlagenliste',
  product_line: 'Produktlinie',
  work_order_list: 'Arbeitsauftragsliste',
  favorite_list: 'Favoritenliste',
  search: 'Suche',
  add_product: 'Produkt hinzufügen',
  title: 'Titel',
  original_OEM: 'Ursprünglicher Hersteller',
  frame_type: 'Maschinentyp',
  model: 'Modell',
  part_number: 'Teilenummer',
  location: 'Ort',
  document_number: 'Dokumentnummer',
  revision: 'Revision',
  created_by: 'Erstellt von',
  date: 'Datum',
  edit: 'Bearbeiten',
  delete: 'Löschen',
  duplicate: 'Duplikat',
  copyfrom: 'Kopiere von',
  nothing_here: 'Nichts verfügbar',

  project_number: 'Projektnummer',
  customer: 'Kunde',
  work_order_number: 'Arbeitsauftragsnummer',
  serial_number: 'Seriennummer',
  sales_force_number: 'Salesforce-Opportunity-Nummer',
  add_order: 'Auftrag Hinzufügen',
  original_oem_number: 'Ursprünglicher Herstellernummer',


  task_count: 'Aufgabenzahl',
  selection_from_list: 'Bitte treffen Sie eine Auswahl aus der Liste',
  select_tasks: 'Aufgaben Auswählen',
  select_products: 'Ausgewähltes Produkte',
  selected_products: 'Ausgewählte Produkte',

  select_all: 'Alle auswählen',
  deselect_all: 'Alle abwählen',
  select_site: 'Wählen Sie Site',
  review: 'Überprüfen',
  sites: 'Werksgelände',
  products: 'Produkte',
  tasks: 'Aufgaben',
  comments: 'Comments',

  sign_off: 'Ausloggen',


  create_task: {
    create_new_task: 'Neue Aufgabe erstellen',
    diagram: 'Diagramm',
    instruction: 'Anweisung',
    worksheet: 'Arbeitsblatt',
    task_name: 'Aufgaben Benennung',
    cancel: 'Abbrechen',
    done: 'Erledigt',
    select_software_tool: 'Software-Tool auswählen',
  },

  emfProductList: {
    'workOrderList': 'Arbeitsauftragsliste',
    'search': 'Suche',
    'addOrder': 'Auftrag hinzufügen',
    'next': 'Nächster',
    'previous': 'Vorherige',


    'productLine': 'Produktlinie',
    'title': 'Titel',
    'originalOEM': 'Ursprünglicher Hersteller',
    'frameType': 'Maschinentyp',
    'model': 'Modell',
    'partNumber': 'Teilenummer',
    'customer': 'Kunde',
    'projectNumber': 'Projektnummer',
    'serialNumber': 'Seriennummer',
    'workOrder': 'Arbeitsauftrag',
    'salesForce': 'Salesforce-Opportunity-Nummer',
    'createdBy': 'Erstellt von',
  },

  loginSession: {
    'admin': 'Administrator',
    'editor': 'Editor',
    'publisher': 'Veröffentlichen',
    'inspector': 'Inspektor',
    'report': 'Bericht',
    'dashboard': 'Instrumententafel',
    'language': 'Sprache',
    'profile': 'Profil',
    'viewProfile': 'Profil anzeigen',
    'manageFleetData': 'Flottendaten verwalten',
    'reportIssue': 'Melde ein Problem',
    'requestContent': 'Inhalt anfordern',
    'contactUs': 'Kontaktiere Uns',
    'training': 'Schulung',
    'logOut': 'Ausloggen'
  },

  create_new_site_page:
  {
    siteName:'Site Name',
    city:'Stadt',
    state:'Zustand',
    country:'Land',
    create_new_site:'Neue Site erstellen',
    all_fields_required: 'Bitte füllen Sie alle erforderlichen Felder aus.',
    site_failed: 'Site-Erstellung fehlgeschlagen.',
    site_success: 'Site erfolgreich erstellt.',
  },

  edit_site_page:
  {
    edit_site: 'Seite bearbeiten',
    siteName:'Site Name',
    city:'Stadt',
    state:'Zustand',
    country:'Land',
    rat:'Vorlage zur Reparaturbewertung',
    mte_m:'MTE-Verwaltung',
    delete:'Löschen',
    update:'aktualisieren',
    successMessage: 'Site erfolgreich aktualisiert.',
    failedMessage: 'Aktualisierung der Site fehlgeschlagen.',
    delete_confirm:'Sind Sie sicher, dass Sie diese Site löschen wollen?',
    deletesite: 'Site erfolgreich gelöscht.',
    deletefail: 'Site konnte nicht gelöscht werden.',
  },

  site_list:
  {
    siteName:'Site Name',
    city:'Stadt',
    state:'Zustand',
    country:'Land',
    DateModified:'Geändertes Datum'
  },
};

export default de;
