<template>
  <div class="wrapper">
    <div class="login">
      <div class="se-logo" />
      <div class="content">
        <div class="dij-logo">
          <div class="abbreviation">DIJ</div>
          <div class="name">
            <div class="name-top">Digital</div>
            <div class="name-bottom">Inspection Jacket</div>
          </div>
        </div>
        <div class="loading">
          <div class="loading-icon"></div>
          <div class="loading-text">Logging in...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login, handleRedirect, acquireToken } from '../../services/auth';

export default {
  name: 'Login',
  async created() {
    try {
      const splittedReferrer = document.referrer.split('/');
      const path = splittedReferrer[splittedReferrer.length - 1];
      if (path === 'callback') {
        await handleRedirect();
      } else {
        await login();
      }
      await acquireToken();
      // Will only arrive here if the login was successful (coming from callback)
      let isNewUser = false;
      await this.$store
        .dispatch('getCurrentUserProfile')
        .then(
          () => (isNewUser = this.$store.state.currentUser.isPendingApproval)
        )
        .catch(() => {
          isNewUser = true;
          return this.createNewUser();
        });
      await this.$store.dispatch('login');
      const redirectPath = localStorage.getItem('path');
      if (isNewUser) {
        this.$router.push({ path: '/new-user-welcome-page' });
      } else if (redirectPath) {
        localStorage.removeItem('path');
        this.$router.push({ path: redirectPath });
      } else {
        this.$router.push({ path: '/view/dashboard' });
      }
    } catch (e) {
      console.error(
        'Failed to login. This page requires the user to be logged in',
        e.message
      );
      throw new Error(
        'Failed to login. This page requires the user to be logged in'
      );
    }
  },
  methods: {
    async createNewUser() {
      await this.$store.dispatch('createUserSelf');
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login {
  width: 100%;
  background-color: white;
  margin: 20px;
  padding: 20px;
  box-shadow: 0px 0px 5px grey;
}

.se-logo {
  width: 100px;
  height: 50px;
  background-image: url('/img/se_logo_color_rgb.svg');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  margin-bottom: 50px;
}

.content {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dij-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: rgb(100, 30, 140);
  /*margin: 0 0 0 -4px;*/

  .abbreviation {
    font-size: 96px;
    border-right: 2px solid rgb(100, 30, 140);
    line-height: 62px;
    height: 82px;
    padding-right: 10px;
  }

  .name {
    font-size: 36px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }

  .name-top {
    height: 48px;
  }

  .name-bottom {
    height: 48px;
    margin-bottom: 5px;
  }
}

.loading {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-icon {
  width: 100px;
  height: 100px;
  animation: spin 750ms infinite linear;
  border: 2px solid #b9b9b9;
  border-radius: 100px;
  border-right-color: transparent;
  border-top-color: transparent;
}

.loading-text {
  position: absolute;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
</style>
