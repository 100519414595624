export default {
  GET_TASK_QUESTIONS: (state) => {
    // Sort the questions based on the label property
    const sortedQuestions = [...state.questions].sort((a, b) => {
      return parseInt(a.label, 10) - parseInt(b.label, 10);
    });

    return sortedQuestions;
  },

  FETCH_QUESTION_LABEL: (state) => {
    // Create a set to store existing labels
    const labelSet = new Set(
      state.questions.map((question) => parseInt(question.label, 10))
    );

    // Find the smallest positive integer not used as a label
    let nextLabel = 1;
    while (labelSet.has(nextLabel)) {
      nextLabel++;
    }

    return nextLabel.toString();
  },
};
